import * as React from 'react'
import dayjs from 'dayjs'
import Badge from '@mui/material/Badge'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton'
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Card,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  IconButton,
  Button,
  CircularProgress,
} from '@mui/material'
import CardContent from '@mui/material/CardContent'
import { TextField } from '@mui/material'
import {
  AirplanemodeInactive,
  ArrowBack,
  ArrowCircleLeft,
  BackHand,
  CheckBox,
  CheckBoxOutlineBlank,
  EditTwoTone,
} from '@mui/icons-material'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import { getFlightListApi } from '../../services/admin.configService'
import {
  availableStatus,
  createSeatAvailability,
  getSeatRenderAvailability,
  getSeatcategorylist,
  postUpdateseatrateandavailability,
} from '../../services/configService'
import DashboardLayout from '../dashboard_layout/DashboardLayout'
import { SnackbarContext } from '../../context/Snackbar'
import Modal from '@mui/material/Modal'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 0.5,
}

function getRandomNumber(min, max) {
  return Math.round(Math.random() * (max - min) + min)
}

function fakeFetch(date, { signal }) {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      const daysInMonth = date.daysInMonth()
      const daysToHighlight = [1, 2, 3].map(() =>
        getRandomNumber(1, daysInMonth)
      )

      resolve({ daysToHighlight })
    }, 500)

    signal.onabort = () => {
      clearTimeout(timeout)
      reject(new DOMException('aborted', 'AbortError'))
    }
  })
}
const date = new Date()
date.toLocaleDateString()
const initialValue = dayjs(date)

const flightless = ['Economy', 'Business']

export default function DateCalendarServerRequest() {
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)
  const requestAbortController = React.useRef(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [dataLoader, setDataLoader] = React.useState(false)
  const [highlightedDays, setHighlightedDays] = React.useState([1, 2, 15])
  const [conditionData, setConditionData] = React.useState(0)
  const [masterId, setMasterId] = React.useState(0)
  const [flightList, setFlightList] = React.useState([])
  const [flightavailable, setFlightavailable] = React.useState([])
  const [getseatdata, setGetseatdata] = React.useState([])
  const [dateData, setDateData] = React.useState([])
  const [flightFromCode, setFlightFromCode] = React.useState('DEL')
  const [error, setError] = React.useState({})
  const [particulardatedate, setParticulardatedate] = React.useState({})
  const [selectedFlightCode, setSelectedFlightCode] = React.useState('')
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [isChecked, setIsChecked] = React.useState(false)
  const [editdate, setEditdate] = React.useState('')
  const [flight, setFlight] = React.useState({
    available: 1,
    price: 17000,
  })

  const handleInputChange = (index, field, value) => {
    const newData = [...dateData]

    newData[index][field] = +value
    setDateData(newData)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setConditionData(0)
    setDateData([])
  }
  const open = Boolean(anchorEl)

  const id = open ? editdate : undefined

  const getSeatData = async (id, date) => {
    try {
      const sendData = {
        flight_available_id: id,
        flight_date: date,
      }
      const res = await getSeatRenderAvailability(sendData, setError)

      if (res !== 204 && res !== 401) {
        setConditionData(1)
        setMasterId(res.data.flight_date_master_id)
       

        setDateData((prev) =>
          prev.map((item, index) => {
            item.id = res.data.data[index].id
            item.no_of_seats = res.data.data[index].no_of_seats
            item.price = res.data.data[index].price
            return item
          })
        )

        const seatsData = res.data.data.map((item) => {
          return {
            id: item.id,
            remaining_balance_seats: item.remaining_balance_seats,
            no_of_seats: item.no_of_seats,
          }
        })
        setGetseatdata(seatsData)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getSeatcategorylists = async (flightId, date) => {
    try {
      const sendData = {
        flight_available_id: flightId,
        flight_date: date,
      }
      const res = await getSeatcategorylist()
      const res2 = await getSeatRenderAvailability(sendData, setError)
      if (res2 !== 204 && res2 !== 401) setConditionData(1)
      if (res !== 401) {
        setMasterId(res2?.data?.flight_date_master_id || 0)
  

        setDateData(
          res.data.map((item, index) => {
            item.category_id = item.id
            item.id = res2?.data?.data[index]?.id
            item.class_id = item.classId
            item.flight_categorywise_seat_availability_id = item.id || 0
            item.no_of_seats = res2?.data?.data[index]?.no_of_seats || 0
            item.price = res2?.data?.data[index]?.price || 0
            item.remaining_balance_seats =
              res2?.data?.data[index]?.remaining_balance_seats || 0
            return item
          })
        )

        const seatsData = res2.data.data.map((item) => {
          return {
            id: item.id,
            remaining_balance_seats: item.remaining_balance_seats,
            no_of_seats: item.no_of_seats,
          }
        })
        setGetseatdata(seatsData)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchHighlightedDays = (date) => {
    const controller = new AbortController()
    fakeFetch(date, {
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight)
        setIsLoading(false)
      })
      .catch((error) => {
        if (error.name !== 'AbortError') {
          throw error
        }
      })

    requestAbortController.current = controller
  }

  const getFlightList = async () => {
    try {
      const res = await getFlightListApi()
      if (res !== 204 && res !== 401) {
        setFlightList(res?.data)
      }
    } catch (error) {
      alert(error.message)
    }
  }

  const disableDateFrom = (calDate) => {
    // let days = flightList
    //   .filter((item) => item.flight_code == selectedFlightCode)[0]
    //   ?.flight_days.split(',')
    //   .map(Number)
    let days = flightList
      .filter((item) => item.flight_code == selectedFlightCode)[0]
      ?.day_time.map((item) => item.flight_day)

    setFlightavailable(
      flightList.filter(
        (item) =>
          item.flight_departure_from == flightFromCode &&
          item.flight_code == selectedFlightCode
      )[0]?.flight_available
    )

    const flatDays = days?.flat(1)
    const currentDay = new Date(calDate).getDay()

    return !flatDays?.includes(currentDay)
  }

  function ServerDay(props) {
    const {
      highlightedDays = [],
      day,
      minDate,
      outsideCurrentMonth,
      ...other
    } = props

    const handleClick = async (event) => {
      setDataLoader(true)
      const dateKey = dayjs(day).format('YYYY-MM-DD') + selectedFlightCode
      const date = dayjs(day).format('YYYY-MM-DD')
      const flightCodeData = flightList.find(
        (item) => item.flight_code == selectedFlightCode
      )?.flight_available
      const flightId = flightCodeData?.find((item) => item.code == dateKey)?.id
      const flightData = flightCodeData?.find((item) => item.id == flightId)

      getSeatcategorylists(flightId, date)
      setEditdate(dateKey)
      // getSeatData(flightId, date)
      setParticulardatedate(flightData)
      setAnchorEl(event.currentTarget)
    }

    const selectmindate = dayjs(minDate)

    const isSelected = disableDateFrom(day)
    const selectOutsideCurrentMonth = outsideCurrentMonth ? true : false

    return (
      <Badge
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        key={props.day.toString()}
        overlap='circular'
        badgeContent={
          !isSelected &&
          !selectOutsideCurrentMonth &&
          dayjs(day).isAfter(selectmindate) ? (
            <>
              <div
                style={{
                  display: 'flex',
                  marginLeft: '55px',
                  marginTop: '3px',
                }}
              >
                <Checkbox
                  size='medium'
                  // value={dayjs(day).format('YYYY-MM-DD')}
                  // onClick={(e)}
                  checked={
                    flightavailable?.find(
                      (item) =>
                        item.code ==
                        dayjs(day).format('YYYY-MM-DD') + selectedFlightCode
                    )?.is_available
                      ? true
                      : false
                  }
                  // checked={isChecked}
                  disabled
                  // onClick={handleCheckboxChange}
                  // color={isChecked ? 'error' : 'success'}
                />
                <div>
                  <IconButton
                    sx={{ paddingTop: 2 }}
                    color='info'
                    size='large'
                    aria-describedby={id}
                    onClick={handleClick}
                    // onClick={() => handleEdit(passenger.id)}
                  >
                    <EditTwoTone />
                  </IconButton>
                </div>
              </div>
            </>
          ) : undefined
        }
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
          onClick={(e, v) => console.log({ ...e })}
          className={`abc ${outsideCurrentMonth && 'disabled'}`}
          style={{
            padding: '35px', // Adjust day width as needed
            fontSize: '18px', // Adjust font size as needed
            borderRadius: 0,
            // border: outsideCurrentMonth &&  '1px solid #ccc', // Apply grey border for days outside current month
            background: `${isChecked ? 'rgb(255 172 176)' : ''}`,
          }}
        />
      </Badge>
    )
  }

  const sumByClassId = dateData.reduce((acc, item) => {
    if (!acc[item.class_id]) {
      acc[item.class_id] = 0
    }
    acc[item.class_id] += item.no_of_seats
    return acc
  }, {})

  const handleSaveFormData = async () => {
    try {
      // Find flight ID based on selected flight code and date
      const flightCodeData = flightList.find(
        (item) => item.flight_code === selectedFlightCode
      )?.flight_available
      const flightId = flightCodeData?.find(
        (item) => item.code === editdate
      )?.id

      // Handle case where flight ID is not found
      if (!flightId) {
        showAlert('error', 'Flight ID not found')
        return
      }

      // Validate seat constraints
      const { e_total_seat, b_total_seat } = particulardatedate
      if (sumByClassId[1] > e_total_seat) {
        showAlert(
          'error',
          'Sum of seats for Economy Class exceeds total Economy seats allowed.'
        )
        return
      }

      if (sumByClassId[2] > b_total_seat) {
        showAlert(
          'error',
          'Sum of seats for Business Class exceeds total Business seats allowed.'
        )
        return
      }

      // Prepare common data for API calls
      const commonData = {
        flight_available_id: flightId,
        flight_date: editdate?.slice(0, 10),
        is_active: 0,
      }

      // Prepare request data based on conditionData
      const sendData =
        conditionData === 1
          ? {
              ...commonData,
              flight_date_master_id: masterId,
              flightDetails: dateData.map((item) => ({
                ...item,
                flight_categorywise_seat_availability_id: item.id || 0,
              })),
            }
          : {
              ...commonData,
              api_type: 'create',
              flightDetails: dateData,
            }

      // API call
      const apiMethod =
        conditionData === 1
          ? postUpdateseatrateandavailability
          : createSeatAvailability
      const response = await apiMethod(sendData, setError)

      // Handle response
      if (response !== 204 && response !== 406) {
        const successMessage =
          conditionData === 1 ? 'Update Successfully' : 'Edited Successfully'
        showAlert('success', successMessage)
        resetForm()
      }
    } catch (error) {
      showAlert('error', 'Something went wrong')
    }
  }

  // Helper functions
  const showAlert = (severity, message) => {
    setSeverity(severity)
    setMes(message)
    setOn(true)
  }

  const resetForm = () => {
    handleClose()
    setDateData([])
  }

  React.useEffect(() => {
    fetchHighlightedDays(initialValue)
    getFlightList()
  }, [])

  React.useEffect(() => {
    setTimeout(() => {
      setDataLoader(false)
    }, 3000)
  })

  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      requestAbortController.current.abort()
    }

    setIsLoading(true)
    setHighlightedDays([])
    fetchHighlightedDays(date)
  }

  return (
    <DashboardLayout
      heading='Set Seat Availability'
      pathName='Set Seat Availability'
    >
      <Grid container spacing={2}>
        <Grid item md={3} xs={3} mt={4}>
          <FormControl sx={{ marginLeft: '20px' }} fullWidth size='small'>
            <InputLabel id='demo-simple-select-label'>Select Origin</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              className='form_style'
              value={flightFromCode}
              label='Select Origin'
              onChange={(e) => {
                setFlightFromCode(e.target.value)
              }}
            >
              <MenuItem value={'DEL'}>Delhi</MenuItem>
              <MenuItem value={'BOM'}>Mumbai</MenuItem>
              <MenuItem value={'GYD'}>Baku</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            style={{ marginTop: '20px', marginLeft: '20px' }}
            size='small'
          >
            <InputLabel id='flight-code-label'>Select Flight Code</InputLabel>
            <Select
              labelId='flight-code-label'
              id='demo-simple-select'
              className='form_style'
              value={selectedFlightCode}
              label='Select Flight Code'
              onChange={(e) => setSelectedFlightCode(e.target.value)}
            >
              {flightList
                .filter((item) => item.flight_departure_from === flightFromCode)
                .map((item) => (
                  <MenuItem
                    key={item.flight_departure_from}
                    value={item.flight_code}
                  >
                    {item.flight_code} ({item.flight_departure_to})
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <div style={{ display: 'flex', gap: '5px', marginLeft: '20px' }}>
            <Chip
              label='Seat Available'
              variant='outlined'
              // color='success'
              icon={<CheckBox fontSize='small' />}
              sx={{ marginTop: '20px' }}
            />
            <Chip
              label='Seat Not Available'
              variant='outlined'
              sx={{ marginTop: '20px' }}
              icon={<CheckBoxOutlineBlank fontSize='small' />}
            />
          </div>
        </Grid>
        <Grid item md={1} xs={1}></Grid>
        <Grid item md={8} xs={8} sx={{ background: 'white' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              showDaysOutsideCurrentMonth
              defaultValue={initialValue}
              loading={isLoading}
              onMonthChange={handleMonthChange}
              renderLoading={() => <DayCalendarSkeleton />}
              slots={{
                day: ServerDay,
              }}
              slotProps={{
                day: {
                  highlightedDays,

                  style: {
                    width: '90px', // Adjust day label width as needed
                    fontSize: '14px', // Adjust day label font size as needed
                  },
                },
              }}
              shouldDisableDate={(date) => disableDateFrom(date)}
              minDate={dayjs(date)}
              className='customday'
              sx={{
                width: '100%',
                height: '100%',
                maxHeight: '100%',
                paddingBottom: '30px',
                '& .MuiDayCalendar-header': {
                  gap: '37px', // Add a gap of 60px to MuiDayCalendar-header
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Card sx={style}>
          <CardContent sx={{ paddingBottom: '5px', paddingTop: '5px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <IconButton aria-label='delete' onClick={handleClose}>
                  <ArrowCircleLeft />
                </IconButton>
                <Typography sx={{ fontWeight: 510, fontSize: '16px' }}>
                  Category wise Seat Availability
                </Typography>
              </div>
              <div>
                <Button
                  variant='outlined'
                  size='medium'
                  onClick={handleSaveFormData}
                >
                  save
                </Button>
              </div>
            </div>
          </CardContent>
          <CardContent
            sx={{ background: '#dce3ea', overflow: 'auto', height: '60vh' }}
          >
            <Grid container spacing={2}>
              <Grid item md={3} xs={3}>
                <TextField
                  type='text'
                  value={particulardatedate.flight_code}
                  variant='outlined'
                  fullWidth
                  size='small'
                  label='Flight Code'
                />
              </Grid>
              <Grid item md={3} xs={3}>
                <TextField
                  type='text'
                  value={particulardatedate.date}
                  variant='outlined'
                  fullWidth
                  size='small'
                  label='Flight Date'
                />
              </Grid>
              <Grid item md={3} xs={3}>
                <TextField
                  type='number'
                  value={particulardatedate.e_total_seat}
                  variant='outlined'
                  fullWidth
                  size='small'
                  label='Total Economy Seats'
                />
              </Grid>
              <Grid item md={3} xs={3}>
                <TextField
                  type='number'
                  value={particulardatedate.b_total_seat}
                  variant='outlined'
                  fullWidth
                  size='small'
                  label='Total Business Seats'
                />
              </Grid>

              <Grid item md={2.4}>
                <Typography>Category</Typography>
              </Grid>
              <Grid item md={2.4}>
                <Typography>Class</Typography>
              </Grid>
              <Grid item md={2.4}>
                <Typography>Available Seat</Typography>
              </Grid>
              <Grid item md={2.4}>
                <Typography>Price</Typography>
              </Grid>
              <Grid item md={2.4}>
                <Typography>Seat Balance</Typography>
              </Grid>
            </Grid>
            {dataLoader === true ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <CircularProgress size='30px' />
              </div>
            ) : (
              <Grid container spacing={1}>
                {dateData.map((item, index) => {
                  return (
                    flightList && (
                      <>
                        <Grid item md={2.4}>
                          <TextField
                            type='text'
                            value={item.CategoryName}
                            // onChange={handlePriceChange}
                            variant='outlined'
                            fullWidth
                            sx={{ backgroundColor: '#b4b4b8', color: 'white' }}
                            disabled
                            size='small'
                          />
                        </Grid>

                        <Grid item md={2.4}>
                          <TextField
                            type='text'
                            value={flightless[item.classId - 1]}
                            variant='outlined'
                            sx={{
                              backgroundColor: '#b4b4b8',
                              color: 'white',
                              cursor: 'not-allowed',
                            }}
                            fullWidth
                            disabled
                            size='small'
                          />
                        </Grid>
                        <Grid item md={2.4}>
                          <TextField
                            type='number'
                            placeholder='0'
                            value={item?.no_of_seats || ''}
                            onChange={(e) => {
                              const maxSeats =
                                flightless[item.classId - 1] === 'Economy'
                                  ? particulardatedate.e_total_seat
                                  : particulardatedate.b_total_seat
                              const value = Number(e.target.value)

                              if (value <= maxSeats && value >= 0) {
                                handleInputChange(index, 'no_of_seats', value)
                                const remainingBalanceSeats =
                                  conditionData == 1
                                    ? value -
                                      (getseatdata[index]?.no_of_seats -
                                        getseatdata[index]
                                          ?.remaining_balance_seats)
                                    : value

                                handleInputChange(
                                  index,
                                  'remaining_balance_seats',
                                  remainingBalanceSeats
                                )
                              }
                            }}
                            variant='outlined'
                            fullWidth
                            size='small'
                            error={
                              error[`flightDetails[${index}].no_of_seats`]
                                ? true
                                : false
                            }
                          />
                        </Grid>
                        <Grid item md={2.4}>
                          <TextField
                            type='number'
                            placeholder='0'
                            value={item.price || ''}
                            onChange={(e) =>
                              handleInputChange(index, 'price', e.target.value)
                            }
                            variant='outlined'
                            fullWidth
                            size='small'
                            error={
                              error[`flightDetails[${index}].price`]
                                ? true
                                : false
                            }
                          />
                        </Grid>
                        <Grid item md={2.4}>
                          <TextField
                            type='number'
                            name='remaining_balance_seats'
                            value={
                              conditionData == 1
                                ? dateData[index]?.no_of_seats -
                                  (getseatdata[index]?.no_of_seats -
                                    getseatdata[index]?.remaining_balance_seats)
                                : dateData[index]?.remaining_balance_seats
                            }
                            variant='outlined'
                            sx={{
                              backgroundColor: '#b4b4b8',
                              color: 'red !important',
                              cursor: 'not-allowed',
                            }}
                            fullWidth
                            disabled
                            size='small'
                          />
                        </Grid>
                      </>
                    )
                  )
                })}
              </Grid>
            )}
          </CardContent>
        </Card>
      </Modal>
    </DashboardLayout>
  )
}
