import React, { useContext, useReducer, useRef } from 'react'
import {
  Divider,
  IconButton,
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  Button,
  Modal,
  Autocomplete,
  Checkbox,
  Paper,
  CircularProgress,
} from '@mui/material'
// import Field from '../components/molecules/field';
// import FormLinker from 'form-linker'
import DashboardLayout from '../../../components/dashboard_layout/DashboardLayout'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import MyStatefulEditor from '../../../components/Text Editor/Texteditor'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import {
  AccountBox,
  AccountCircle,
  Add,
  AttachFile,
  Attachment,
  Create,
  DeleteSweepTwoTone,
  DownloadTwoTone,
  Group,
  GroupAdd,
  Info,
  Padding,
  RefreshOutlined,
  Settings,
  SwitchAccessShortcutAdd,
  VerifiedUser,
} from '@mui/icons-material'
import Box from '@mui/material/Box'
import MuiPhoneNumber from 'mui-phone-number'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import SpeedDialAction from '@mui/material/SpeedDialAction'

import dayjs from 'dayjs'
import { downloadAttachment } from '../../../utils/common'
import { useParams } from 'react-router-dom'
import {
  appendQuery,
  appendSearchQueryCode,
  deleteCollaborator,
  getAllCollaboratorListApi,
  getAllUserListApi,
  getCollaboratorListApi,
  getEmailTemplate,
  getSummaryOfParticularTicket,
  imapCreateCollaboratorApi,
  imapInternalNote,
  imapPostReplyApi,
  updateCollaborator,
  updateTicketStatus,
  userImapThread,
} from '../../../services/configService'

import { useLocation } from 'react-router-dom'
import { SnackbarContext } from '../../../context/Snackbar'
import CustomTextArea from '../../../components/common/CustomTextArea'
import MultiSelect from '../../../components/quoteMailService/MultiSelect'
import './loaderstyle.css'
import { set } from 'date-fns'
import Reactquilleditor from '../../../components/Text Editor/Reactquilleditor'
import MultiAttachmentSend from '../../../components/common/Multiattachment'
import useAuth from '../../../hooks/useAuth'
// import Field from '../../../components/molecules/field'

import Ckeditor from '../../../components/texteditor/MainTextEditor'
// import fitTemplate from '../../../components/Text Editor/email.html'

import english from '../../../components/utils/translations/en'

import Translator from 'simple-translator'
import {
  globalStyle,
  theme as theme2,
} from '../../../components/utils/theme.config'

import { Global, ThemeProvider as Theme2 } from '@emotion/react'
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import iconLibrary from '../../../components/utils/iconLIbrary'
import Field from '../../../components/molecules/field'
import FormLinker from 'form-linker'
config.autoAddCss = false
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const loaderstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #2A67AC',
  boxShadow: 24,
  p: 2,
}
const sstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  border: '2px solid #2A67AC',
  boxShadow: 24,
  p: 2,
}

const ImapThreads = () => {
  iconLibrary()
  Translator.registerDefaultLanguage('en', english)
  const { auth } = useAuth()
  const { setOn, setSeverity, setMes } = useContext(SnackbarContext)
  const { state } = useLocation()
  const [outerValue, setOuterValue] = React.useState(0)
  const [innerValue, setInnerValue] = React.useState(0) // Separate state for inner Tabs
  const [data, setData] = React.useState([])
  const [templatedata, setTemplateData] = React.useState([])
  const [collaboratorId, setCollaboratorId] = React.useState('')
  const [ticketstatus, setTicketstatus] = React.useState(0)
  const [upcollaboratorId, setUpcollaboratorId] = React.useState('')
  const [dltcollaboratorId, setDltcollaboratorId] = React.useState('')
  const [collaboratorData, setCollaboratorData] = React.useState([])
  const [summaryData, setSummaryData] = React.useState([])
  const [appendData, setAppendData] = React.useState({})
  const [collaboratorDataCopy, setCollaboratorDataCopy] = React.useState([])
  const [allCollaboratorData, setAllCollaboratorData] = React.useState([])
  const [allUserData, setAllUserData] = React.useState([])
  const [collaboratorEmail, setCollaboratorEmail] = React.useState([])
  const [cancelEmail, setCancelEmail] = React.useState([])
  const [ccEmailId, setCCEmailId] = React.useState([])
  const [matchingemails, setMatchingemails] = React.useState([])
  const [cancelccemails, setCancelccemails] = React.useState([])
  const [deactiveCollaborator, setDeactiveCollaborator] = React.useState([])
  const [error, setError] = React.useState({})
  const [editorLoading, setEditorLoading] = React.useState(true)
  const [newCollaboratorsdata, setNewCollaboratorsdata] = React.useState({
    name: '',
    email: '',
    phone: '',
    internal_note: '',
    reply_to: 2,
  })
  const [loader, setLoader] = React.useState(false)

  const [internoteTitle, setInternoteTitle] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [openManageCollaborator, setOpenManageCollaborator] =
    React.useState(false)
  const [selectedFiles, setSelectedFiles] = React.useState([])
  const [editorHtml, setEditorHtml] = React.useState('')
  const [openStatusModal, setOpenStatusModal] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')
  const [queryId, setQueryId] = React.useState('')
  const [options, setOptions] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [selectedTemplate, setSelectedTemplate] = React.useState('')
  // const [templateContent, setTemplateContent] = React.useState('')
  const [_, forceUpdate] = React.useState(0)

  const demoContent = ``

  const formLinker = React.useRef(
    new FormLinker({
      data: {
        editor: demoContent,
      },
      schema: {
        editor: 'string',
      },
    })
  )

  const handleOpenStatusModal = () => setOpenStatusModal(true)
  const handleCloseStatusModal = () => setOpenStatusModal(false)

  const handleChangeCollaborator = (e, v) => {
    setCollaboratorId(v?.id)
  }

  const handleChangePhone = (e, v, v1, v2) => {
    let x = e
    let num = x.replace(/\D/g, '')
    // let dialCode = num.slice(0, 2)
    setNewCollaboratorsdata((pre) => ({
      ...pre,
      phone: num,
    }))
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setNewCollaboratorsdata((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleClose = () => {
    setOpen(false)
    setCollaboratorId('')
  }

  async function getEmailTempData() {
    try {
      const res = await getEmailTemplate()
      if (res !== 406 && res !== 204) {
        setTemplateData(res?.data?.data)
        // console.log('res.data', res.data)
      } else {
        setTemplateData([])
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleCloseManageCollaborator = () => {
    setOpenManageCollaborator(false)
  }
  const handleCollaboratorStatus = (id) => {
    setUpcollaboratorId(id)
    setCollaboratorData((prevCollaborators) =>
      prevCollaborators.map((collaborator) =>
        collaborator.id === id
          ? {
              ...collaborator,
              status: collaborator.status === '0' ? '1' : '0',
            }
          : collaborator
      )
    )
  }

  async function handleupdateCollaborator(ccemail) {
    const data = {
      thread_id: state?.thread_id,
      // cc_id:
      //   collaboratorData.map(({ collaborator_id }) => collaborator_id) || id,
      // status: collaboratorData.map(({ status }) => status),
      data: collaboratorData || ccemail,
    }
    try {
      const res = await updateCollaborator(data)
      if (res.status !== 204 && res.status !== 406) {
        getCollaboratorData()
        // setUpcollaboratorId('')
        setSeverity('success')
        setMes('successfully changed')
        setOn(true)
        setOpenManageCollaborator(false)
      }
    } catch (error) {
      console.log(error)
    }
  }
  async function handleCollaboratorDelete(id) {
    // const data = {
    //   thread_id: state?.thread_id,
    //   cc_id: id,
    // }
    try {
      const res = await deleteCollaborator(id)
      if (res.status !== 204 && res.status !== 406) {
        getCollaboratorData()

        setSeverity('error')
        setMes('Collaborator Deleted Successfully')
        setOn(true)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // const handleCollaboratorDelete = (id) => {
  //   console.log('id', id)
  //   setDltcollaboratorId(id)
  //   handleupdateCollaborator(id)
  // }

  const handleControl = () => setCollaboratorId('')

  async function getData() {
    try {
      const res = await userImapThread(state.ticket_no || state.thread_id)
      if (res.status !== 204) {
        setData(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  async function getCollaboratorData() {
    try {
      const res = await getCollaboratorListApi(
        state.ticket_no || state.thread_id
      )
      if (res.status !== 204 && res.status !== 406) {
        setCollaboratorData(res.data)
        setCollaboratorDataCopy(
          // res.data?.filter((item) => item.status === 'active')
          res.data
        )
        // const emailIds = res.data
        //   ?.filter((item) => item.status === 'active')
        //   ?.map((item) => item.default_email_id)
        // setCollaboratorEmail(emailIds)
        // console.log('exammmmmmm', emailIds)
      }
    } catch (error) {
      console.log(error)
    }
  }
  async function getAllCollaboratorData() {
    try {
      const res = await getAllCollaboratorListApi()
      if (res.status !== 204 && res.status !== 406) {
        setAllCollaboratorData(res.data?.filter((item) => item.status === '0'))
        // const emailIds = res.data
        //   ?.filter((item) => item.status === 'active')
        //   ?.map((item) => item.default_email_id)
        // setCollaboratorEmail(emailIds)
        // console.log('exammmmmmm', emailIds)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function getAllUserData() {
    try {
      const res = await getAllUserListApi()
      if (res.status !== 204 && res.status !== 406) {
        setAllUserData(res.data?.filter((item) => item.status === 'active'))
        // const emailIds = res.data
        //   ?.filter((item) => item.status === 'active')
        //   ?.map((item) => item.default_email_id)
        // setCollaboratorEmail(emailIds)
        // console.log('exammmmmmm', emailIds)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function getSummaryOfTicket() {
    try {
      const res = await getSummaryOfParticularTicket(
        state.ticket_no || state.thread_id
      )
      if (res.status !== 204 && res.status !== 406) {
        setSummaryData(res?.data[0])
        setAppendData(res?.data[1])
        // const emailIds = res.data
        //   ?.filter((item) => item.status === 'active')
        //   ?.map((item) => item.default_email_id)
        // setCollaboratorEmail(emailIds)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleOuterChange = (event, newValue) => {
    setOuterValue(newValue)
  }

  const handleInnerChange = (event, newValue) => {
    setInnerValue(newValue)
  }

  const handleTextChange = (html) => {
    setEditorHtml(html)
  }
  const handleRefresh = () => {
    getData()
    getCollaboratorData()
    getAllCollaboratorData()
    getSummaryOfTicket()
    getAllUserData()
  }

  const adminmessage = data
    ?.map((item) => {
      if (item.type === 'R') {
        return {
          message: item.mail_body,
          created_at: item.updated,
          poster: item.poster,
          type: item.type,
          title: item.title,
          file: item.attachments,
          user: item.emp_name,
        }
      }
      return null // or filter out falsy values later
    })
    .filter((item) => item !== null)

  const usermessage = data
    ?.map((item) => {
      if (item.type === 'M') {
        return {
          message: item.mail_body,
          created_at: item.updated,
          poster: item.poster,
          type: item.type,
          title: item.title,
          file: item.attachments,
          user: item.poster,
        }
      }
      return null // or filter out falsy values later
    })
    .filter((item) => item !== null)
  const internoteThread = data
    ?.map((item) => {
      if (item.type === 'N') {
        return {
          message: item.mail_body,
          created_at: item.updated,
          poster: item.poster,
          type: item.type,
          title: item.title,
          file: item.attachments,
          user: item.emp_name,
        }
      }
      return null // or filter out falsy values later
    })
    .filter((item) => item !== null)

  const messages = [
    ...adminmessage.map((msg) => ({ ...msg, isAdmin: true })),
    ...usermessage.map((msg) => ({ ...msg, isAdmin: false })),
    ...internoteThread.map((msg) => ({ ...msg, isAdmin: true })),
  ]

  messages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))

  const generatePostReply = async () => {
    if (newCollaboratorsdata.reply_to === '') {
      setSeverity('error')
      setMes('Please select Reply to')
      setOn(true)
      return
    } else if (editorHtml === '') {
      setSeverity('error')
      setMes('Please write something...')
      setOn(true)
      return
    }
    setLoader(true)
    const ccEmails = newCollaboratorsdata.reply_to === 1 ? ccEmailId : []
    const recipients =
      newCollaboratorsdata.reply_to === 1 || newCollaboratorsdata.reply_to === 2
        ? state.email_id
        : []
    const status = newCollaboratorsdata.reply_to === 3 ? '' : 1

    const sendData = new FormData()
    sendData.append('thread_id', state?.thread_id)
    sendData.append('mail_body', editorHtml)
    sendData.append('cc_user', JSON.stringify(ccEmails))
    sendData.append('emp_id', auth.id)
    sendData.append('emp_role', auth.role)
    selectedFiles.forEach((file, index) => {
      sendData.append(`files[${index}]`, file) // Append files using array-like keys
    })

    sendData.append('status', status)

    // const data = {
    //   thread_id: state?.thread_id,
    //   mail_body: editorHtml,
    //   cc_user: ccEmails,
    //   // Recipients: recipients,
    //   file: selectedFiles,
    //   status: status,
    // }

    try {
      const res = await imapPostReplyApi(sendData, setError)

      if (res !== 204 && res !== 406) {
        // Handle success response
        setNewCollaboratorsdata({ reply_to: 2 })
        setEditorHtml('')

        setSelectedTemplate('')
        // setSelectedTemplate('')
        getData()
        setSelectedFiles([])
        setTimeout(() => {
          setEditorLoading(true)
        }, 500)
        setSeverity('success')
        setMes('Successfully sent the reply')
        setOn(true)
        setLoader(false)
      }
    } catch (error) {
      console.error('Error in generate quote', error)
      setSeverity('warning')
      setMes('Failed to send the reply.')
      setOn(true)
      setLoader(false)
    } finally {
      setLoader(false)
      // Ensure loader is stopped in all cases
    }
  }

  console.log(editorLoading, 'editorLoading', formLinker.current.data.editor)

  const changeTicketStatus = async (e) => {
    setTicketstatus(e.target.value)
    const data = {
      ticket_no: state.ticket_no,
      status: e.target.value,
    }

    try {
      const res = await updateTicketStatus(data)

      if (res !== 204 && res !== 406) {
        // const mailRes = await quoteMailService(mailData, setError)
        // console.log('mailRes', mailRes)

        setSeverity('success')
        setMes('Thread Status changed successfully')
        setOn(true)
        getSummaryOfTicket()
        setOpenStatusModal(false)
      }
    } catch (error) {
      setSeverity('warning')
      setMes('')
      setOn(true)
      // console.log('error in generate quote ', error)
    } finally {
    }
  }
  const generateInternalNote = async () => {
    if (editorHtml === '') {
      setSeverity('error')
      setMes('Please write something...')
      setOn(true)
      return // Ensure early return to stop further execution when content is empty
    }

    setLoader(true)
    const data = new FormData()

    data.append('title', internoteTitle)
    data.append('type', 'N')
    data.append('thread_id', state?.thread_id || state?.ticket_no)
    data.append('content', editorHtml)
    data.append('emp_id', auth.id)
    data.append('emp_role', auth.role)
    selectedFiles.forEach((file, index) => {
      data.append(`files[${index}]`, file) // Append files using array-like keys
    })

    try {
      const res = await imapInternalNote(data)

      if (res !== 204 && res !== 406) {
        setInternoteTitle('')
        setEditorHtml('')
        setSelectedFiles([])
        getData()
        setSeverity('success')
        setMes('Successfully sent the reply')
        setOn(true)
      }
    } catch (error) {
      console.error('Error in generate quote', error)
      setSeverity('warning')
      setMes('Failed to send the reply.')
      setOn(true)
    } finally {
      setLoader(false) // Stop the loader in all cases
    }
  }

  const createNewCollaborator = async (change) => {
    const data = {
      thread_id: state?.thread_id,
      email: newCollaboratorsdata.email || change.default_email_id || '',
      name: newCollaboratorsdata.name || change.name || '',
      phone: newCollaboratorsdata.phone || change.phone || '', // not required
      internal_note:
        newCollaboratorsdata.internal_note || change.internal_note || '', // not required
    }

    try {
      const res = await imapCreateCollaboratorApi(data, setError)

      if (res !== 204 && res !== 406) {
        // const mailRes = await quoteMailService(mailData, setError)

        setNewCollaboratorsdata({
          name: '',
          email: '',
          phone: '',
          internal_note: '',
        })
        setOpen(false)
        setOpenManageCollaborator(true)
        getCollaboratorData()
        setSeverity('success')
        setMes('Collaborator added successfully')
        setOn(true)
      }
    } catch (error) {
      setSeverity('warning')
      setMes('')
      setOn(true)
      // console.log('error in generate quote ', error)
    } finally {
    }
  }

  const searchQuerycode = async (change) => {
    const data = {
      search_text: change,
    }

    try {
      const res = await appendSearchQueryCode(data, setError)

      if (res !== 204 && res !== 406) {
        setOptions(res.data)
      } else {
        setOptions([])
      }
    } catch (error) {
      setSeverity('warning')
      setMes('')
      setOn(true)
      // console.log('error in generate quote ', error)
    } finally {
    }
  }

  const handleInputChange = async (_, value) => {
    setInputValue(value)
    const query_code = value?.split(' ')[0]
    const regex = /\(([^)]+)\)/ // Matches content inside parentheses
    const match = value.match(regex)
    const email = match ? match[1] : ''

    if (value.length > 2) {
      // Fetch suggestions when input has 3+ chars
      setLoading(true)
      const results = await searchQuerycode(value)
      // setOptions(results || []) // Update options with fetched data
      setLoading(false)
    } else {
      setOptions([]) // Clear options for shorter input
    }
    const x = options.find(
      (a) => a.email === email || a.query_code === query_code
    )
    setQueryId(x?.query_id)
  }

  const handleAppendQuery = async () => {
    const data = {
      ticket_no: state?.thread_id || state?.ticket_no,
      query_id: queryId,
      imap_email: summaryData?.email_id,
    }

    try {
      const res = await appendQuery(data, setError)

      if (res !== 204 && res !== 406) {
        setSeverity('success')
        setMes('Query Code was successfully binded.')
        setOn(true)
        setInputValue('')
        setQueryId('')
        getData()
        getCollaboratorData()
        getAllCollaboratorData()
        getSummaryOfTicket()
        getAllUserData()
      }
    } catch (error) {
      setSeverity('warning')
      setMes('')
      setOn(true)
      // console.log('error in generate quote ', error)
    } finally {
    }
  }

  const handleNewCollaboratorModal = () => {
    setOpen(true)
    // Add your logic for adding new collaborators here
  }
  // console.log('error', appendData)

  const handleManageCollaborator = () => {
    setOpenManageCollaborator(true)
    // Add your logic for managing collaborators here
  }

  const handleContinue = () => {
    const change = allUserData.find((item) => item.id === collaboratorId)

    if (data) {
      createNewCollaborator(change)
    }
    // setOpenManageCollaborator(true)
    // setOpen(false)
    setCollaboratorId('')
  }
  const actions = [
    {
      icon: <GroupAdd />,
      name: 'Add New Collaborators',
      onClick: handleNewCollaboratorModal,
    },
    {
      icon: <Settings />,
      name: 'Manage Collaborators',
      onClick: handleManageCollaborator,
    },
  ]

  const selectedCollaborator = allUserData?.find(
    (item) => item.id === collaboratorId
  )
  const handleTemplateChange = (event) => {
    const selectedId = event.target.value

    setSelectedTemplate(selectedId)

    // formLinker.current.data.editor = template?.html_format || ''

    // // // Force re-render to reflect the changes in your text editor
    // forceUpdate((prev) => prev + 1)
  }

  React.useEffect(() => {
    getData()
    getCollaboratorData()
    getAllCollaboratorData()
    getSummaryOfTicket()
    getAllUserData()
    getEmailTempData()
  }, [])
  React.useEffect(() => {
    if (summaryData?.closed_status) {
      setTicketstatus(summaryData?.closed_status)
    }
  }, [summaryData])

  React.useEffect(() => {
    const emailIds = collaboratorDataCopy
      ?.filter((item) => item.status === '1')
      .map((item) => item.default_email_id)
    setCollaboratorEmail(emailIds)
  }, [collaboratorDataCopy])

  React.useEffect(() => {
    const userccCollaborator = collaboratorData
      ?.filter((item) => item.status === '0')
      ?.map((item) => item.default_email_id)
    const deactiveCollaborator = collaboratorData?.filter(
      (item) => item.status === '1'
    )

    setDeactiveCollaborator(deactiveCollaborator)

    if (userccCollaborator) {
      setCCEmailId(userccCollaborator)
    } else {
      setCCEmailId([]) // Or handle this case accordingly
    }
  }, [collaboratorData])

  React.useEffect(() => {
    const matchingEmails = deactiveCollaborator.filter((item) =>
      ccEmailId.includes(item.default_email_id)
    )

    // Update the status of matching emails to 0
    matchingEmails.forEach((item) => {
      item.status = 0
    })
    setMatchingemails(matchingEmails) // Update the state with the updated matchingEmails

    // handleupdateCollaborator(matchingEmails)
  }, [ccEmailId, deactiveCollaborator])
  React.useEffect(() => {
    const cancelemails = collaboratorData.filter((item) =>
      cancelEmail.includes(item.default_email_id)
    )

    // Update the status of matching emails to 0
    cancelemails.forEach((item) => {
      item.status = 1
    })
    setCancelccemails(cancelemails) // Update the state with the updated matchingEmails
  }, [cancelEmail])

  React.useEffect(() => {
    if (matchingemails.length > 0) {
      handleupdateCollaborator(matchingemails)
    }
  }, [matchingemails])
  React.useEffect(() => {
    if (cancelccemails.length > 0) {
      handleupdateCollaborator(cancelccemails)
    }
  }, [cancelccemails])

  React.useEffect(() => {
    if (error.duplicate_entry) {
      setSeverity('warning')
      setMes(
        error.duplicate_entry
          ? error.duplicate_entry
          : // : error.pan_id
            // ? error.pan_id
            // : error.gst_id
            // ? error.gst_id
            'Error'
      )
      setOn(true)
    }
  }, [error])

  React.useEffect(() => {
    if (selectedTemplate) {
      const template =
        templatedata.find((format) => format.id === selectedTemplate) || ''
      setEditorHtml(template?.html_format || '')
    }
  }, [selectedTemplate])

  console.log('state', formLinker.current.data)
  return (
    <Theme2 theme={theme2}>
      <Global styles={globalStyle} />
      <DashboardLayout
        heading='Mail Threads Listing'
        pathName='Mail Threads Listing'
      >
        <Card>
          <Modal
            open={loader}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={loaderstyle}>
              <div class='mailloader'></div>
            </Box>
          </Modal>
          <CardContent>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                variant='h6'
                sx={{ fontWeight: 'bold', textDecoration: 'underline' }}
              >
                {state?.title}
              </Typography>
              <Button
                variant='outlined'
                startIcon={<RefreshOutlined />}
                onClick={handleRefresh}
              >
                Refresh
              </Button>
            </div>
            <CardContent
              sx={{
                background: '#e9f4ff',
                borderTop: '1px solid lightgrey',
                marginTop: '8px',
                padding: '6px',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '20px',
                      margin: '16px',
                    }}
                  >
                    <Typography style={{ fontWeight: 'bold' }}>
                      Status:
                    </Typography>
                    {/* <Typography style={{ fontWeight: '500', color: '#002244' }}>
                    {summaryData?.closed_status === 1 ? 'Closed' : 'Open'}
                  </Typography> */}
                    <Button
                      style={{
                        fontWeight: '500',
                        color: '#002244',
                        textDecoration: 'underline',
                        padding: 0,
                      }}
                      variant='text'
                      onClick={handleOpenStatusModal}
                    >
                      {summaryData?.closed_status === 1 ? 'Closed' : 'Open'}
                    </Button>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '20px',
                      margin: '16px',
                    }}
                  >
                    <Typography style={{ fontWeight: 'bold' }}>
                      Priority:
                    </Typography>
                    <Typography style={{ fontWeight: '500', color: '#002244' }}>
                      {summaryData?.priority}
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '20px',
                      margin: '16px',
                    }}
                  >
                    <Typography style={{ fontWeight: 'bold' }}>
                      Create Date:
                    </Typography>
                    <Typography style={{ fontWeight: '500', color: '#002244' }}>
                      {dayjs(new Date(summaryData?.first_last_updated)).format(
                        'DD/MM/YYYY HH:mm:ss a'
                      )}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '30px',
                      margin: '16px',
                    }}
                  >
                    <Typography style={{ fontWeight: 'bold' }}>
                      Organization:
                    </Typography>
                    <Typography style={{ fontWeight: '500', color: '#002244' }}>
                      {summaryData?.org_name}
                    </Typography>
                  </div>
                  {appendData && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '30px',
                        margin: '16px',
                      }}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>
                        Query Code:
                      </Typography>
                      <Typography
                        style={{ fontWeight: '500', color: '#002244' }}
                      >
                        {appendData?.query_code}
                      </Typography>
                    </div>
                  )}
                </Grid>
                <Grid item md={6}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '20px',
                      margin: '16px',
                    }}
                  >
                    <Typography style={{ fontWeight: 'bold' }}>
                      User:
                    </Typography>
                    <Typography style={{ fontWeight: '500', color: '#002244' }}>
                      {summaryData?.poster}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '20px',
                      margin: '16px',
                    }}
                  >
                    <Typography style={{ fontWeight: 'bold' }}>
                      Email:
                    </Typography>
                    <Typography style={{ fontWeight: '500', color: '#002244' }}>
                      {summaryData?.email_id}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '20px',
                      margin: '16px',
                    }}
                  >
                    <Typography style={{ fontWeight: 'bold' }}>
                      Source:
                    </Typography>
                    <Typography style={{ fontWeight: '500', color: '#002244' }}>
                      {summaryData?.source}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '30px',
                      margin: '16px',
                    }}
                  >
                    <Typography style={{ fontWeight: 'bold' }}>
                      Last updated:
                    </Typography>
                    <Typography style={{ fontWeight: '500', color: '#002244' }}>
                      {dayjs(new Date(summaryData?.last_last_updated)).format(
                        'DD/MM/YYYY HH:mm:ss a'
                      )}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              {!appendData && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '30px',
                    margin: '16px',
                  }}
                >
                  <Typography style={{ fontWeight: 'bold' }}>
                    Select Query code:
                  </Typography>
                  <Autocomplete
                    sx={{ width: '450px' }}
                    size='small'
                    // disabled={queryId ? tru : true}
                    options={options}
                    getOptionLabel={(option) =>
                      `${option.query_code} (${option.email})`
                    }
                    onInputChange={handleInputChange}
                    renderOption={(props, option) => (
                      <Box
                        component='li'
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <Typography variant='subtitle2' color='initial'>
                          {option.query_code}
                        </Typography>{' '}
                        &nbsp;
                        <Typography variant='subtitle2' color='#3a87ad'>
                          ({option.email})
                        </Typography>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Search by Email or Query Code'
                        variant='outlined'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading ? (
                                <CircularProgress color='inherit' size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                  <Button
                    startIcon={<SwitchAccessShortcutAdd />}
                    onClick={handleAppendQuery}
                    variant='contained'
                    disabled={queryId ? false : true}
                  >
                    Bind
                  </Button>
                </div>
              )}
            </CardContent>
          </CardContent>

          <CardContent>
            <Box sx={{ width: '100%', padding: '0px' }}>
              <Box
                sx={{ borderBottom: 1, borderColor: 'divider', padding: '0px' }}
              >
                <Tabs
                  value={outerValue}
                  onChange={handleOuterChange}
                  aria-label='basic tabs example'
                >
                  <Tab label='Email Threads' {...a11yProps(0)} />
                  {/* <Tab label='Task' {...a11yProps(1)} /> */}
                  {/* <Tab label='Item Three' {...a11yProps(2)} /> */}
                </Tabs>
              </Box>
              <CustomTabPanel
                value={outerValue}
                index={0}
                sx={{ Padding: '0px' }}
              >
                <CardContent sx={{ padding: '1px', borderRadius: '0px' }}>
                  {messages.length == 0 ? (
                    <Typography
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                      variant='h5'
                      color={'GrayText'}
                    >
                      No Chat Found
                    </Typography>
                  ) : (
                    messages.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          marginBottom: '5px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: item.isAdmin ? 'flex-start' : 'flex-end',
                        }}
                      >
                        {/* <Typography
                          variant='h6'
                          sx={{
                            marginLeft: '7px',
                            color: item.isAdmin ? '#002244' : '#002244',
                            fontWeight: 500,
                            fontSize: '15px',
                            marginBottom: '10px',
                            marginLeft: item.isAdmin ? '0' : '48px',
                          }}
                        >
                          {item.isAdmin ? 'Admin' : 'User'}
                        </Typography> */}

                        <div
                          style={{
                            display: 'flex',
                            gap: '10px',
                            width: '100%',
                          }}
                        >
                          {item.isAdmin && <AccountCircle fontSize='large' />}
                          <Card elevation={5} sx={{ width: '100%' }}>
                            <CardContent
                              sx={{
                                background:
                                  item.type === 'M'
                                    ? '#C3D9FF'
                                    : item.type === 'N'
                                    ? '#ffffc1'
                                    : '#FFE0B3',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Typography
                                  // sx={{
                                  //   background: item.isAdmin ? '#F0F8FF' : '#D0F0C0',
                                  //   border: item.isAdmin
                                  //     ? '1px solid #008E97'
                                  //     : '1px solid #00853E',
                                  //   borderRadius: '15px',
                                  //   padding: '4px 10px',
                                  //   cursor: 'pointer',
                                  //   fontSize: '14px',
                                  //   marginRight: item.isAdmin ? '40px' : '0',
                                  //   marginLeft: item.isAdmin ? '0' : '40px',
                                  //   color: 'black',
                                  //   fontWeight: 500,
                                  //   width: 'fit-content',
                                  // }}
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: '15px',
                                    color: item.isAdmin ? '#002244' : '#002244',
                                  }}
                                >
                                  {item.user == null
                                    ? 'Admin'
                                    : item?.user?.charAt(0).toUpperCase() +
                                      item?.user?.slice(1)}
                                </Typography>

                                <Typography
                                  variant='caption'
                                  display='block'
                                  sx={{
                                    marginLeft: '10px',
                                    fontWeight: 550,
                                    fontSize: '15px',

                                    // marginLeft: item.isAdmin ? '0' : '0px',
                                  }}
                                >
                                  posted{' '}
                                  {/* {dayjs(new Date(item.created_at)).format(
                                  'DD/MM/YY HH:mm:ss a'
                                )} */}
                                  {dayjs(new Date(item.created_at)).format(
                                    'DD/MM/YY HH:mm:ss A'
                                  )}
                                </Typography>
                                <Typography
                                  variant='caption'
                                  display='block'
                                  sx={{
                                    marginLeft: '10px',
                                    // fontWeight: 550,
                                    fontSize: '15px',

                                    // marginLeft: item.isAdmin ? '0' : '0px',
                                  }}
                                >
                                  (
                                  {item.title !== 'null'
                                    ? item.title
                                    : 'No Title'}
                                  )
                                </Typography>
                              </div>
                            </CardContent>
                            <CardContent>
                              <Typography
                                sx={{ fontSize: 16, fontWeight: 500 }}
                                // color='text.secondary'
                                gutterBottom
                              >
                                {/* <pre>{item.message}</pre> */}
                                <div
                                  className='message88'
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      item.user == null
                                        ? item?.message
                                        : item?.message?.replace(
                                            /\n/g,
                                            '<br/>'
                                          ),
                                  }}
                                  // dangerouslySetInnerHTML={{
                                  //   __html: item?.message,
                                  // }}
                                />
                                {/* <div
                              className='message88'
                              dangerouslySetInnerHTML={{
                                __html: item?.filepaths?.replace(
                                  /\n/g,
                                  '<br/>'
                                ),
                              }}
                            /> */}
                              </Typography>
                            </CardContent>
                            {item?.file && (
                              <CardContent
                                sx={{
                                  // border: '1px dotted',
                                  padding: '0px',
                                  paddingBottom: '3px !important',
                                  background: '#e3f2fd',
                                  margin: '10px',
                                  borderRadius: '10px',
                                }}
                              >
                                <Grid container spacing={2}>
                                  {item?.file
                                    ?.split(',')
                                    ?.map((fileUrl, index) => (
                                      <Grid item xs={12} md={3} key={index}>
                                        <IconButton
                                          key={index}
                                          aria-label='download'
                                          size='medium'
                                          // color='info'
                                          sx={{
                                            // padding: '8px 12px', // Adds more padding for a comfortable click area
                                            fontSize: '15px', // Adjust font size for readability
                                            display: 'flex',
                                            alignItems: 'center', // Aligns icon and text vertically
                                            gap: '5px', // Adds space between the icon and text
                                            textDecoration: 'Underline',
                                            color: '#0d47a1',
                                            fontWeight: 500,
                                          }}
                                          onClick={() =>
                                            downloadAttachment(
                                              fileUrl,
                                              `Attachment File ${index + 1}`
                                            )
                                          }
                                        >
                                          {fileUrl && (
                                            <AttachFile fontSize='small' />
                                          )}
                                          <span>
                                          {fileUrl.split('amazonaws.com/')[1]}
                                          </span>
                                        </IconButton>
                                      </Grid>
                                    ))}
                                </Grid>
                              </CardContent>
                            )}
                          </Card>
                          {!item.isAdmin && <AccountCircle fontSize='large' />}
                        </div>

                        <Divider sx={{ marginBottom: '15px' }} />
                      </div>
                    ))
                  )}
                </CardContent>

                <CardContent sx={{ padding: '0px' }}>
                  <Box sx={{ width: '100%', padding: '0px' }}>
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        padding: '0px',
                      }}
                    >
                      <Tabs
                        value={innerValue}
                        onChange={handleInnerChange}
                        aria-label='basic tabs example'
                      >
                        <Tab label='Post Reply' {...a11yProps(0)} />
                        <Tab label='Post Internal Note' {...a11yProps(1)} />
                        {/* <Tab label='Item Three' {...a11yProps(2)} /> */}
                      </Tabs>
                    </Box>
                    <CustomTabPanel
                      value={innerValue}
                      index={0}
                      sx={{ Padding: '0px' }}
                    >
                      <Grid container spacing={2} style={{ margin: '16px 0' }}>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          lg={2}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Typography style={{ fontWeight: 'bold' }}>
                            From:
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={8} lg={9}>
                          <FormControl sx={{ width: '300px' }} size='small'>
                            <InputLabel id='from-select-label'>From</InputLabel>
                            <Select
                              className='form_style'
                              size='small'
                              labelId='from-select-label'
                              id='from-select'
                              label='From'
                              value={10}
                              // onChange={handleChange}
                            >
                              <MenuItem value={10}>
                                j2sales@zeal-global.com
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={2} lg={2}>
                        <Typography style={{ fontWeight: 'bold' }}>
                          From:
                        </Typography>
                      </Grid> */}

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          lg={2}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Typography style={{ fontWeight: 'bold' }}>
                            Recipients:
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={8}
                          lg={9}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <FormControl
                            sx={{ width: '300px', marginRight: '10px' }}
                            size='small'
                          >
                            <InputLabel id='recipients-select-label'>
                              Recipients
                            </InputLabel>
                            <Select
                              className='form_style'
                              size='small'
                              labelId='recipients-select-label'
                              id='recipients-select'
                              label='Recipients'
                              value={1}
                              // onChange={handleChange}
                            >
                              <MenuItem value={1}> {state?.email_id}</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          lg={2}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Typography style={{ fontWeight: 'bold' }}>
                            Collaborators:
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={8}
                          lg={9}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <FormControl
                            sx={{ width: '300px', marginRight: '10px' }}
                            size='small'
                          >
                            <MultiSelect
                              userList={collaboratorEmail}
                              label='Select Email Id'
                              emailId={ccEmailId}
                              setEmailId={setCCEmailId}
                              setCancelEmail={setCancelEmail}
                            />
                          </FormControl>

                          <Box
                            sx={{
                              transform: 'translateZ(0px)',
                              // flexGrow: 1,
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <SpeedDial
                              ariaLabel='SpeedDial basic example'
                              icon={<Group />}
                              direction='right'
                            >
                              {actions.map((action) => (
                                <SpeedDialAction
                                  key={action.name}
                                  icon={action.icon}
                                  tooltipTitle={action.name}
                                  onClick={action.onClick}
                                />
                              ))}
                            </SpeedDial>
                          </Box>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          lg={2}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Typography style={{ fontWeight: 'bold' }}>
                            Reply To:
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={8} lg={9}>
                          <FormControl sx={{ width: '300px' }} size='small'>
                            <InputLabel id='reply-to-select-label'>
                              Reply To
                            </InputLabel>
                            <Select
                              name='reply_to'
                              className='form_style'
                              size='small'
                              labelId='reply-to-select-label'
                              id='reply-to-select'
                              label='Reply To'
                              value={newCollaboratorsdata.reply_to}
                              onChange={handleChange}
                            >
                              <MenuItem value={1}>
                                All Active Recipients
                              </MenuItem>
                              <MenuItem value={2}>
                                Ticket Owner ({state?.email_id})
                              </MenuItem>
                              <MenuItem value={3}>
                                - Do Not Email Reply -
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          lg={2}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Typography style={{ fontWeight: 'bold' }}>
                            Email Template:
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={8} lg={9}>
                          <FormControl
                            sx={{ width: '300px' }}
                            fullWidth
                            size='small'
                          >
                            <InputLabel id='template-select-label'>
                              Select Email Template
                            </InputLabel>
                            <Select
                              labelId='template-select-label'
                              className='form_style'
                              label='Select Email Template'
                              value={selectedTemplate}
                              onChange={handleTemplateChange}
                            >
                              {templatedata.map((format) => (
                                <MenuItem key={format.id} value={format.id}>
                                  {format.template_name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <CardContent sx={{ pb: 1 }}>
                        {/* <Reactquilleditor
                        theme='snow'
                        placeholder='Write your reply here...'
                        setEditorHtml={setEditorHtml}
                        editorHtml={editorHtml}
                        handleChange={handleTextChange}
                      /> */}
                        {/* <div>
                          {/* <Field
                            formLinker={formLinker.current}
                            name='editor'
                            type='editor'
                            minHeight={150}
                            height={620}
                            maxHeight={800}
                            placeholder='Enter your content here'
                            toolbar={['withImages']}
                            onChange={forceUpdate}
                          /> */}
                        {/* {editorLoading && (
                            <Field
                              formLinker={formLinker.current}
                              name='editor'
                              type='editor'
                              minHeight={150}
                              height={620}
                              maxHeight={400}
                              placeholder='Enter your content here'
                              toolbar={['withImages']}
                              onChange={forceUpdate}
                            />
                          )} */}

                        {/* {formLinker.current.data.editor && (
                            <div
                              style={{
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center', // Center vertically
                                justifyContent: 'space-between', // Center horizontally
                                marginTop: 30,
                                marginBottom: 10,
                              }}
                            >
                              <h4
                                style={{
                                  margin: 0,
                                  textDecoration: 'underline',
                                }}
                              >
                                Your Email Preview
                              </h4>
                              <p
                                style={{
                                  color: 'red',
                                  fontSize: 13,
                                  fontWeight: 500,
                                  margin: 0, // Remove default margin for proper alignment
                                }}
                              >
                                *(To Edit this content Please use the
                                TextEditor)
                              </p>
                            </div>
                          )}
                          {formLinker.current.data.editor && (
                            <div
                              style={{
                                width: '100%',
                                padding: 12,
                                border: '1px solid #000',
                                minHeight: 350,
                              }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: formLinker.current.data.editor,
                                }}
                              ></div>
                            </div>
                          )} */}
                        {/* </div> */}
                        <Ckeditor value={editorHtml} onChange={setEditorHtml} />
                      </CardContent>
                      <CardContent sx={{ pt: 0 }}>
                        <MultiAttachmentSend
                          selectedFiles={selectedFiles}
                          setSelectedFiles={setSelectedFiles}
                        />
                      </CardContent>
                      <CardContent
                        sx={{ display: 'flex', gap: 5, alignItems: 'center' }}
                      >
                        <Typography style={{ fontWeight: 'bold' }}>
                          Thread Status:
                        </Typography>
                        <FormControl sx={{ width: '300px' }} size='small'>
                          <InputLabel id='response-select-label'>
                            Thread status
                          </InputLabel>
                          <Select
                            className='form_style'
                            size='small'
                            labelId='response-select-label'
                            id='response-select'
                            label='Thread status'
                            value={ticketstatus}
                            onChange={changeTicketStatus}
                          >
                            <MenuItem value={0}>Open (Currently)</MenuItem>
                            {/* <MenuItem value={1}>Resolved</MenuItem> */}
                            <MenuItem value={1}>Closed</MenuItem>
                          </Select>
                        </FormControl>
                      </CardContent>
                      <Button
                        variant='contained'
                        sx={{ float: 'right' }}
                        onClick={generatePostReply}
                      >
                        Post Reply
                      </Button>
                    </CustomTabPanel>
                    <CustomTabPanel value={innerValue} index={1}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '30px',
                          margin: '16px',
                        }}
                      >
                        <Typography style={{ fontWeight: 'bold' }}>
                          Internal Note:
                        </Typography>
                        <TextField
                          id='outlined-basic'
                          label='Note title - summary of the note (optional)'
                          variant='outlined'
                          value={internoteTitle}
                          onChange={(e) => setInternoteTitle(e.target.value)}
                          sx={{ width: '400px' }}
                          size='small'
                        />
                      </div>

                      <CardContent>
                        <Reactquilleditor
                          theme='snow'
                          placeholder='Write Message...'
                          setEditorHtml={setEditorHtml}
                          editorHtml={editorHtml}
                          handleChange={handleTextChange}
                        />
                      </CardContent>
                      <CardContent sx={{ pt: 0 }}>
                        <MultiAttachmentSend
                          selectedFiles={selectedFiles}
                          setSelectedFiles={setSelectedFiles}
                        />
                      </CardContent>
                      <Button
                        variant='contained'
                        sx={{ float: 'right' }}
                        onClick={generateInternalNote}
                      >
                        Post Note
                      </Button>
                    </CustomTabPanel>
                  </Box>
                </CardContent>
              </CustomTabPanel>
              <CustomTabPanel value={outerValue} index={1}>
                Task
              </CustomTabPanel>
            </Box>
          </CardContent>
        </Card>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Card sx={style}>
            <Typography
              id='modal-modal-title'
              variant='h6'
              component='h2'
              color={'#2A67AC'}
              borderBottom={'1px solid #C8C8C8'}
            >
              Add a Collaborators
            </Typography>
            <Typography
              sx={{
                mt: 2,
                fontSize: '15px',
                fontWeight: '500',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
              bgcolor={'#d9edf7'}
              color={'#3a87ad'}
              border={'1px solid #bce8f1'}
            >
              {' '}
              <Info />
              Search existing users or add a new user.
            </Typography>
            <CardContent
              sx={{ padding: '0px', marginTop: '10px', marginBottom: '20px' }}
            >
              <Autocomplete
                disablePortal
                options={allUserData}
                getOptionLabel={(option) =>
                  `${option.name} ${option.default_email_id}`
                }
                fullWidth
                sx={{
                  // width: 'min(400px,100%)',
                  // background: '#f2f2f2',
                  borderRadius: 2,
                }}
                onChange={(e, v) => handleChangeCollaborator(e, v)}
                renderOption={(props, option) => (
                  <Box
                    component='li'
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <Typography variant='subtitle2' color='initial'>
                      {option.name}
                    </Typography>{' '}
                    &nbsp;
                    <Typography variant='subtitle2' color='#3a87ad'>
                      ({option.default_email_id})
                    </Typography>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Search By Name or Email'
                    // error={fieldError?.agent}
                    // helperText={
                    //   fieldError?.agent && 'Please select Agent'
                    // }
                    fullWidth
                  />
                )}
                size='small'
              />

              {!collaboratorId && (
                <Typography
                  sx={{
                    mt: 2,
                    fontSize: '15px',
                    fontWeight: '500',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                  }}
                  bgcolor={'#eee'}
                  color={'#000'}
                  border={'1px dotted #bbb'}
                >
                  {' '}
                  <Create />
                  Create New User
                </Typography>
              )}
            </CardContent>
            {!collaboratorId && (
              <CardContent>
                <Grid container spacing={2} sx={{ gap: 2, marginLeft: '10px' }}>
                  <Grid
                    itm
                    md={11}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography sx={{ fontWeight: 'bold' }}>
                      Email Address:
                    </Typography>

                    <TextField
                      label='Email'
                      size='small'
                      sx={{ width: '350px' }}
                      name='email'
                      value={newCollaboratorsdata.email}
                      // error={error && error['full_name']}
                      onChange={(e) => handleChange(e, 'email')}
                    />
                  </Grid>
                  <Grid
                    itm
                    md={11}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography sx={{ fontWeight: 'bold' }}>Name:</Typography>
                    <TextField
                      label='Name'
                      size='small'
                      sx={{ width: '350px' }}
                      name='name'
                      value={newCollaboratorsdata.name}
                      // error={error && error['full_name']}
                      onChange={(e) => handleChange(e, 'name')}
                    />
                  </Grid>
                  <Grid
                    itm
                    md={11}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography sx={{ fontWeight: 'bold' }}>
                      Phone No:
                    </Typography>
                    <MuiPhoneNumber
                      className='form_style'
                      variant='outlined'
                      name='phone'
                      value={newCollaboratorsdata.phone}
                      defaultCountry={'in'}
                      onChange={(e, v) => handleChangePhone(e, v)}
                      // error={error && error['contact']}
                      size='small'
                      sx={{ width: '350px' }}
                    />
                  </Grid>
                  <Grid
                    itm
                    md={11}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography sx={{ fontWeight: 'bold' }}>
                      Internal Note:
                    </Typography>
                    <div style={{ padding: '0px', width: '350px' }}>
                      <CustomTextArea
                        placeholder={'Message...'}
                        name={'internal_note'}
                        value={newCollaboratorsdata.internal_note}
                        onChange={(e) => handleChange(e, 'internal_note')}
                      />
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            )}
            {collaboratorId && (
              <CardContent
                sx={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}
              >
                <Grid container spacing={2}>
                  <Grid item md={3}>
                    <AccountBox
                      sx={{ color: 'lightgrey', fontSize: '100px' }}
                    />
                  </Grid>
                  <Grid item md={9}>
                    <Typography variant='h6' color={'#3a87ad'}>
                      {selectedCollaborator?.name}
                    </Typography>
                    <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                      &lt;{selectedCollaborator?.default_email_id}&gt;
                    </Typography>
                    <Typography
                      sx={{
                        mt: 1,
                        fontsize: '18px',
                        fontWeight: 'bold',
                        borderBottom: '2px dotted #000',
                      }}
                    >
                      Contact Information
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,

                        mt: 1,
                      }}
                    >
                      Phone Number-{' '}
                      <p style={{ fontWeight: 500 }}>
                        {!selectedCollaborator?.phone === null
                          ? selectedCollaborator?.phone
                          : 'NA'}
                      </p>
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      Internal Note-{' '}
                      <p style={{ fontWeight: 500 }}>
                        {!selectedCollaborator?.internal_note === null
                          ? selectedCollaborator?.internal_note
                          : 'NA'}
                      </p>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={2}>
                  <Grid item md={6}>
                    <Button
                      color='error'
                      sx={{ border: '1px solid red' }}
                      onClick={handleClose}
                      size='small'
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item md={6}>
                    <div style={{ display: 'flex', gap: 10, float: 'right' }}>
                      <Button
                        color='inherit'
                        sx={{ border: '1px solid #000' }}
                        onClick={handleControl}
                        size='small'
                      >
                        Add New User
                      </Button>
                      <Button
                        color='info'
                        size='small'
                        sx={{ border: '1px solid #3a87ad' }}
                        onClick={handleContinue}
                      >
                        Continue
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            )}
            {!collaboratorId && (
              <Button
                sx={{
                  float: 'right',
                  background: '#3a87ad',
                  '&:hover': {
                    color: 'black',
                    backgroundColor: '#d9edf7',
                    // transition: 'backgroundColor 0.3s ease',
                  },
                }}
                variant='contained'
                onClick={createNewCollaborator}
              >
                Add User
              </Button>
            )}
          </Card>
        </Modal>
        <Modal
          open={openManageCollaborator}
          onClose={handleCloseManageCollaborator}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Card sx={style}>
            <Typography
              id='modal-modal-title'
              variant='h6'
              component='h2'
              color={'#2A67AC'}
              borderBottom={'1px solid #C8C8C8'}
            >
              Collaborators
            </Typography>

            <CardContent
              sx={{
                background: '#edf1f4',
                width: '100%',
                height: '70vh',
                overflowY: 'auto',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 2px 5px',
                marginTop: '10px',
              }}
            >
              {collaboratorData.map((item) => (
                <Paper
                  key={item.id}
                  sx={{
                    width: '100%',
                    marginBottom: 2,
                    display: 'flex',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 2px 5px',
                  }}
                >
                  <Grid container spacing={2} alignItems='center' padding={1}>
                    <Grid item md={1} xs={1}>
                      <Checkbox
                        checked={item.status === '0'}
                        onChange={() =>
                          handleCollaboratorStatus(item.collaborator_id)
                        }
                      />
                    </Grid>
                    <Grid item md={9} xs={9}>
                      <Typography
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          fontWeight: 500,
                        }}
                      >
                        <AccountBox
                          sx={{ marginRight: 1, color: 'lightgrey' }}
                          fontSize='large'
                        />
                        <span>
                          <strong style={{ color: '#3a87ad' }}>
                            {item?.name}
                          </strong>
                          <br />
                          <p>{item?.default_email_id}</p>
                        </span>
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      md={2}
                      xs={2}
                      sx={{ textAlign: { md: 'right', xs: 'left' } }}
                    >
                      <IconButton
                        aria-label='delete'
                        color='error'
                        size='large'
                        sx={{ paddingTop: { md: 0, xs: 2 } }}
                        // onClick={() => handleSubUserDelete(item)}
                        onClick={() =>
                          handleCollaboratorDelete(item.collaborator_id)
                        }
                      >
                        <DeleteSweepTwoTone />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
            </CardContent>
            <Button
              variant='contained'
              sx={{ float: 'right', mt: 1 }}
              onClick={handleupdateCollaborator}
            >
              Save changes
            </Button>
          </Card>
        </Modal>
        <Modal
          open={openStatusModal}
          onClose={handleCloseStatusModal}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={sstyle}>
            <Typography
              id='modal-modal-title'
              variant='h6'
              component='h2'
              gutterBottom
            >
              Update Thread Status
            </Typography>

            <Typography id='modal-modal-description' sx={{ mb: 3 }}>
              Please update the status of the thread below.
            </Typography>

            <FormControl sx={{ width: '400px', mb: 2 }} size='small'>
              <InputLabel id='response-select-label'> Thread Status</InputLabel>
              <Select
                className='form_style'
                size='small'
                labelId='response-select-label'
                id='response-select'
                label=' Thread Status'
                value={ticketstatus}
                onChange={changeTicketStatus}
              >
                <MenuItem value={0}>Open (Currently)</MenuItem>
                <MenuItem value={1}>Closed</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Modal>
      </DashboardLayout>
    </Theme2>
  )
}

export default ImapThreads
