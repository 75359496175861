import * as React from 'react'
import Box from '@mui/material/Box'
import Switch from '@mui/material/Switch'
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { alpha, styled } from '@mui/material/styles'
import { updateNoShow } from '../../../../services/configService'

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${gridClasses.row}.void`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'white',
    width: '100%',
    backgroundColor: 'lightslategrey',
  },
}))

export default function Prepassengerdetails({
  prePassengerdetails,
  flightcode,
  setPreRefresh,
}) {
  const [noshow, setNoshow] = React.useState(false)
  const [passengerId, setPassengerId] = React.useState(0)

  async function updatepassengernoshow(id, departuredate, flightcode, row) {
    const sendData = {
      is_prelogin: 1,
      is_noshow: noshow ? 1 : 0,
      flight_no: flightcode,
      date: dayjs(departuredate).format('YYYY-MM-DD'),
      pre_booking_id: row.pre_booking_id,
    }
    console.log('skdsadk', row, sendData)
    try {
      const res = await updateNoShow(id, sendData)
      if (res !== 204) {
        console.log('response', res.data)
        setPreRefresh(!noshow)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleNoShow = (row) => {
    setNoshow(!noshow)

    // setPassengerId(row.passenger_id)
    updatepassengernoshow(row.id, row.depart_date, flightcode, row)
  }
  console.log('noshow', noshow, passengerId)
  console.log('prePassengerdetails', prePassengerdetails)
  const columns = [
    { field: 'sno', headerName: 'ID', width: 50 },
    // { field: 'first_name', headerName: 'First name', flex: 1, minWidth: 130 },
    {
      field: 'first_name',
      headerName: 'Passenger Name',
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        return `${params.row.first_name} ${
          params.row.middle_name ? params.row.middle_name : ''
        } ${params.row.last_name ? params.row.last_name : ''}`
      },
    },

    {
      field: 'dob',
      headerName: 'Date of Birth',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return params.row.dob
          ? dayjs(params.row.dob).format('DD-MM-YYYY')
          : 'N.A'
      },
    },

    {
      field: 'mobile_no',
      headerName: 'Phone No.',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'pnr_no',
      headerName: 'PNR No.',
      flex: 1,
      minWidth: 130,
      renderCell: (params) => {
        return params.row.pnr_no ? params.row.pnr_no : 'N.A'
      },
    },

    // {
    //   field: 'actions',
    //   type: 'actions',
    //   headerName: 'No Show',
    //   width: 130,
    //   cellClassName: 'actions',
    //   getActions: ({ row }) => {
    //     return [
    //       <Switch checked={row.no_show} onChange={() => handleNoShow(row)} />,
    //     ]
    //   },
    // },
  ]

  return (
    <Box sx={{ height: '60vh', width: '100%' }}>
      <StripedDataGrid
        rows={prePassengerdetails.map((item, index) => ({
          ...item,
          sno: index + 1,
        }))}
        getRowId={(row) => row?.id}
        columns={columns}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
      />
    </Box>
  )
}
