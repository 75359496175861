import * as React from 'react'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { alpha, styled } from '@mui/material/styles'
import DashboardLayout from '../../../components/dashboard_layout/DashboardLayout'
import { SnackbarContext } from '../../../context/Snackbar'
import {
  cancelPreBookingTicketByPassenger,
  getPreBookingPassengers,
} from '../../../services/configService'
import Deletecard from '../../../components/card/Deletecard'
import TableModal from '../../../components/table_modal/TableModal'

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${gridClasses.row}.void`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'white',
    width: '100%',
    backgroundColor: 'lightslategrey',
  },
}))

export default function PrePassengerList({
  handleClosePassengerModal,
  booking_id,
  getBookingList,
}) {
  const [data, setData] = React.useState([])
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)
  const [isChecked, setIsChecked] = React.useState([])
  const [editable, SetEditable] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false)


  const handleCheckboxChange = (event, row) => {
    setIsChecked((prev) => {
      let newChecked = [...prev]
      console.log('row', row)

      if (event.target.checked) {
        const newObject = {
          id: row.id,
          pre_booking_id: row.pre_booking_id,
          passenger_type: row.passenger_type,
        }
        newChecked.push(newObject)
      } else {
        newChecked = newChecked.filter((item) => item.id !== row.id)
      }
      return newChecked
    })
    SetEditable(true)
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const sendData = {
        passengerList: isChecked,
        booking_id: booking_id,
      }
      let res = await cancelPreBookingTicketByPassenger(sendData, setError)
      if (res != 204 && res != 406) {
        setSeverity('success')
        setMes('Ticket Cancelled Successfully')
        setOn(true)
        getBookingList()
        handleClosePassengerModal()
      }
    } catch (error) {
      console.log(error)
    }finally{
      setIsLoading(false)
    }
  }

  const handleWarningModalOpen = () =>{
   setShowWarningModal(true)
  }

  const getPassengersList = async () => {
    try {
      const res = await getPreBookingPassengers(booking_id)
      if (res === 204) {
        setSeverity('warning')
        setMes('Passenger details not uploaded yet')
        setOn(true)
      }
      if (res !== 204 && res !== 401) {
        setData(res.data)
        // const alreadyChecked = res.data.filter((item) => item.is_cancelled == 1)
        // setIsChecked(alreadyChecked)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleCloseWarningModal = () =>{
   setShowWarningModal(false)
  }

  console.log('isChecked', isChecked, 'booking_id', booking_id)
  console.log('ssss', data)

  React.useEffect(() => {
    getPassengersList()
  }, [booking_id])

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 75,
      cellClassName: 'actions',
      renderCell: (params) => {
        return (
          <Checkbox
            disabled={params.row.is_cancelled}
            defaultChecked={params.row.is_cancelled}
            onChange={(event) => handleCheckboxChange(event, params.row)}
          />
        )
      },
    },
    {
      field: 'Name',
      headerName: 'Name',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return params.row.first_name + ' ' + params.row.last_name
      },
    },
    {
      field: 'dob',
      headerName: 'DOB',
      minWidth: 120,
      renderCell: (params) => {
        return params.row.dob
          ? dayjs(params.row.dob).format('DD-MM-YYYY')
          : 'N.A'
      },
    },
    {
      field: 'mobile_no',
      headerName: 'Mobile No.',
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'email_id',
      headerName: 'Email Id',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'pan_no',
      headerName: 'Pan No.',
      flex: 1,
      minWidth: 140,
    },
    {
      field: 'passport_no',
      headerName: 'Passport No.',
      flex: 1,
      minWidth: 130,
      renderCell : (params) => {
        return params.row.passport_no != "undefined" ? params.row.passport_no : 'N.A'
      }
    },
  ]

  return (
    <Box
      sx={{
        height: '80vh',
        width: '80%',
        backgroundColor: 'white',
        borderRadius: '10px',
      }}
    >
      <DashboardLayout
        text={'Booking Summary'}
        button={true}
        btnText={"Cancel Ticket's"}
        btnColor = {'submitRed'}
        btnBackText={"close"}
        heading={'My Bookings'}
        backBtn={true}
        mainBtnDisabled={isChecked.length > 0 ? false : true}
        onClick={handleWarningModalOpen}
        onClickBack={handleClosePassengerModal}
        pathName={'Cancel Passenger Ticket'}
      >
        <StripedDataGrid
          rows={data?.map((item, index) => ({
            ...item,
          }))}
          getRowId={(row) => row.id}
          columns={columns}
          disableColumnFilter
          disableColumnSelector
          sx={{ height: 'calc(100vh - 13.6rem)' }}
          disableDensitySelector
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: false,
            },
          }}
          disableRowSelectionOnClick
        />
      </DashboardLayout>
      <TableModal
          handleShowModal={showWarningModal}
          handleCloseModal={handleCloseWarningModal}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              width: '100vw',
            }}
          >
            <Deletecard
               onClickBack={handleCloseWarningModal}
               onClickSubmit={handleSubmit}
               btnText={'submit'}
               subheading={'Cancelled Ticket'}
               isLoading={isLoading}
            /> 
          </div>
        </TableModal>
    </Box>
  )
}
