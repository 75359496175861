import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { alpha, styled } from '@mui/material/styles'

import {
  Cancel,
  ContentCopy,
  DeleteSweepTwoTone,
  Payment,
  Send,
} from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { Chip, Tooltip } from '@mui/material'
import { Modal } from '@mui/material'
// import {
//   deletePaymentLinkApi,
//   getAllPaymentLinks,
// } from '../../../../services/configService'
import {
  deletePaymentLinkApi,
  getSettingCharge,
} from '../../../services/configService'
import { SnackbarContext } from '../../../context/Snackbar'
import TableMenu from '../../../components/table_modal/TableMenu'

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: '#E3EFF7',
  },
  [`& .${gridClasses.row}.void`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha('#FFFF00', ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'black',
    width: '100%',
    backgroundColor: '#D3D3D3',
  },
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#61699f',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 1,
  // color: 'skyblue',
}

const AddInvChargeListing = ({ render, setRender }) => {
  const navigate = useNavigate()
  const [data, setData] = React.useState([])
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)
  const [deleteRow, setDeleteRow] = React.useState(false)
  const [isChecked, setIsChecked] = React.useState([])
  const [selectedDltId, setSelectedDltId] = React.useState('')
  const [error, setError] = React.useState('')
  const [editable, SetEditable] = React.useState(false)
  const [mappedRowData, setMappedRowdata] = React.useState([])
  const [paymentdetails, setPaymentdetails] = React.useState([])
  const [open, setOpen] = React.useState(false)
  const [loader, setLoader] = React.useState(false)
  const [copiedRow, setCopiedRow] = React.useState(null)

  const handleCopyLink = (link, id) => {
    navigator.clipboard.writeText(link)
    setCopiedRow(id)

    setTimeout(() => {
      setCopiedRow(null)
    }, 2000)
  }

  async function getData() {
    try {
      const res = await getSettingCharge()
      if (res != 204) {
        setData(res?.data)
        // console.log('res.data', res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  console.log('data', data)

  const handleCheckboxChange = (event, row) => {
    console.log('event.target.checked', event.target.checked)
    setIsChecked((prev) => {
      let newChecked = [...prev]

      if (event.target.checked) {
        const newObject = {
          ...row,
          ded_amt: row.ded_amt ? row.ded_amt : 0,
          amount: row.balance_amount,
          tds_amt: row.paid_tds_amt ? row.paid_tds_amt : row.tds_amt,
          payment_type: row.amount_type,
        }
        newChecked.push(newObject)
      } else {
        newChecked = newChecked.filter((item) => item.p_id !== row.p_id)
      }

      return newChecked
    })
    SetEditable(true)
  }
  const handleDeleteModal = (row) => {
    setSelectedDltId(row.id)
    setDeleteRow(true)
  }
  const handledeleteModalClose = () => setDeleteRow(false)

  const handleDeleteRow = async (row) => {
    try {
      const res = await deletePaymentLinkApi(selectedDltId)
      if (res !== 204 && res !== 406) {
        setSeverity('success')
        setMes('Link Cancelled Successfully')
        setOn(true)
        getData()
        handledeleteModalClose()
      }
    } catch (error) {
      setSeverity('error')
      setMes('link is already in process or has been already used for payment.')
      setOn(true)
    }
  }
  const handleLinkClick = (data) => {
    console.log('hello', data)
    navigate('/imap-threads', { state: data })
  }

  React.useEffect(() => {
    getData()
  }, [])
  React.useEffect(() => {
    if (render === true) {
      getData()
      setRender(false)
    }
  }, [render])

  const columns = [
    // {
    //   field: 'actions',
    //   type: 'actions',
    //   headerName: '',
    //   width: 75,
    //   flex: 0.5,
    //   cellClassName: 'actions',
    //   renderCell: (params) => {
    //     return (
    //       <Checkbox
    //         // checked={isChecked[params.row.p_id]}
    //         onChange={(event) => handleCheckboxChange(event, params.row)}
    //       />
    //     )
    //   },
    // },
    {
      field: 's_no',
      headerName: 'S.No',

      width: 60,
    },

    {
      field: 'charge_name',
      headerName: 'Charge Name',
      flex: 1,
      minWidth: 80,
      renderCell: (params) => {
        return <p style={{ fontWeight: 500 }}>{params.row?.charge_name}</p>
      },
    },

    {
      field: 'foreign_amount',
      headerName: 'Foreign Amount',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return <p style={{ fontWeight: 500 }}>{params.row?.foreign_amount}</p>
      },
    },

    {
      field: 'ex_rate',
      minWidth: 80,
      flex: 1,
      headerName: 'Exchange Amount',

      renderCell: (params) => {
        return <p style={{ fontWeight: 500 }}>{params.row?.ex_rate}</p>
      },
    },
    {
      field: 'inr_amount',
      headerName: 'INR Amount',
      flex: 1,
      minWidth: 80,
      renderCell: (params) => {
        return <p style={{ fontWeight: 500 }}>{params.row?.inr_amount}</p>
      },
    },
    {
      field: 'created_at',
      headerName: 'Created Date',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return (
          <p style={{ fontWeight: 500 }}>
            {params.row?.created_at
              ? dayjs(params.row?.created_at).format('DD-MM-YYYY')
              : 'N.A'}
          </p>
        )
      },
    },
    {
      field: 'updated_at',
      headerName: 'Updated Date',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return (
          <p style={{ fontWeight: 500 }}>
            {params.row?.updated_at == null
              ? 'N.A'
              : dayjs(params.row?.updated_at).format('DD-MM-YYYY')}
          </p>
        )
      },
    },
    // {
    //   field: 'link_status',
    //   headerName: 'Link Status',
    //   disableColumnMenu: 'true',
    //   minWidth: 105,
    //   renderCell: (params) => {
    //     const color =
    //       params.row?.link_status == 'ACTIVE'
    //         ? '#90c9ee'
    //         : params.row.link_status == 'CANCELLED'
    //         ? '#f52f2fe3'
    //         : params.row.link_status == 'PAID'
    //         ? '#3CB371'
    //         : params.row.link_status == 'PENDING'
    //         ? '#FAFAD2'
    //         : params.row.link_status === 7
    //         ? 'secondary'
    //         : 'error'
    //     return params.row?.link_status !== 'undefined' ? (
    //       <Chip
    //         label={params.row?.link_status}
    //         sx={{ background: color, color: 'white', fontWeight: 500 }}
    //         variant='filled'
    //       />
    //     ) : (
    //       'N.A'
    //     )
    //   },
    // },
    // {
    //   field: 'remarks',
    //   headerName: 'Remarks',
    //   flex: 1,
    //   minWidth: 180,
    //   renderCell: (params) => {
    //     const maxWords = 5

    //     // Split the amount_type into an array of words
    //     const words = params.row?.remarks?.split(' ')

    //     // If the word count exceeds the limit, truncate the text and add ellipsis
    //     const truncatedText =
    //       words?.length > maxWords
    //         ? words?.slice(0, maxWords).join(' ') + '...'
    //         : params.row?.remarks
    //     return <strong style={{ color: '#184E81' }}>{truncatedText}</strong>
    //   },
    // },
    // {
    //   field: 'payment_link',
    //   headerName: 'Payment Links',
    //   minWidth: 130,
    //   renderCell: (params) => {
    //     const isCopied = copiedRow === params.row?.id

    //     return (
    //       <Tooltip title={params.row?.payment_link}>
    //         <Button
    //           variant='text'
    //           size='small'
    //           startIcon={<ContentCopy />}
    //           // style={{ textDecoration: 'underline', color: '#184E81' }}
    //           color={isCopied ? 'success' : 'primary'}
    //           onClick={() =>
    //             handleCopyLink(params.row?.payment_link, params.row?.id)
    //           }
    //           disabled={isCopied}
    //         >
    //           {isCopied ? 'Copied!' : 'Copy Link'}
    //         </Button>
    //       </Tooltip>
    //     )
    //   },
    // },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 70,
      cellClassName: 'actions',
      getActions: ({ row }) => {
        return [
          <>
            <TableMenu
              data={[
                {
                  id: 1,
                  title: 'Cancel Payment Link',
                  icon: <Cancel fontSize='small' color='error' />,
                  disable: row?.link_status !== 'ACTIVE' ? true : false,
                  fn: () => handleDeleteModal(row),
                },
              ]}
            />
          </>,
        ]
      },
    },
  ]

  return (
    <Box sx={{ height: '80vh', width: '100%' }}>
      <Modal
        open={loader}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div className='loader-container'>
          <div class='dot-spinner'>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
          </div>
          <p
            style={{
              marginLeft: '10px',
              fontWeight: '500',
              marginTop: '10px',
            }}
          >
            Please wait!!
          </p>
        </div>
      </Modal>

      <StripedDataGrid
        rows={data?.map((item, index) => ({
          ...item,
          s_no: index + 1,
        }))}
        getRowId={(row) => row.id}
        columns={columns}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        getRowClassName={(params) =>
          params.row.is_mapped == 1
            ? 'void'
            : params.indexRelativeToCurrentPage % 2 === 0
            ? 'even'
            : 'odd'
        }
        disableRowSelectionOnClick
      />
      {/* <Modal
        open={deleteRow}
        onClose={handleDeleteModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Deletecard
          btnText={'Cancel Link'}
          onClickBack={handledeleteModalClose}
          onClickSubmit={handleDeleteRow}
          subheading={'Cancel this payment link?'}
        />
      </Modal> */}
    </Box>
  )
}

export default AddInvChargeListing
