import React from 'react'
import DashboardLayout from '../../../../components/dashboard_layout/DashboardLayout'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import MyStatefulEditor from '../../../../components/Text Editor/Texteditor'
// import Tclisting from './Tclisting'
import { Create } from '@mui/icons-material'
import MakeTemples from './MakeTemples'

const EmailTemplate = () => {
  const [open, setOpen] = React.useState(false)
  const [createbtn, setCreatebtn] = React.useState('')
  const handleOpen = () => setOpen(true)

  return (
    <DashboardLayout
      heading='Make Email Template'
      pathName='Make Email Template'
      button={true}
      mainBtnDisabled={true}
      onClick={handleOpen}
      btnText={'Create'}
      btnIcon={<Create />}
    >
      <MakeTemples open={open} setOpen={setOpen} setCreatebtn={setCreatebtn} />
    </DashboardLayout>
  )
}

export default EmailTemplate
