import {
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import './form.css'
import { MuiOtpInput } from 'mui-one-time-password-input'
import Loginform from './Loginform'
import { useNavigate } from 'react-router-dom'
import { handleChangeGlobal } from '../../utils/common'
import { IconButton, InputAdornment } from '@mui/material'
import {
  forgetPassOtpValidate,
  getEmailOtp,
  passwordUpdate,
} from '../../services/configService'
import { SnackbarContext } from '../../context/Snackbar'
import { Cancel, Visibility, VisibilityOff } from '@mui/icons-material'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  p: 4,
}

const Forgotpassword = () => {
  const { setOn, setSeverity, setMes } = useContext(SnackbarContext)
  const theme = useTheme()
  const navigate = useNavigate()
  const [otp, setOtp] = useState('')
  const [otpError, setOtpError] = useState(false)
  const [countdown, setCountdown] = useState(120)
  const [disabled, setDisabled] = useState(false)
  const [open, setOpen] = React.useState(false)
  const [password, setPassword] = useState('')
  const [confirmPass, setConfirmPass] = useState('')
  const [message, setMessage] = useState('')
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [pas, setPas] = useState(true)
  const [showotp, setShowotp] = useState(false)
  const [otpData, setOtpData] = useState({
    email: '',
  })
  const [error, setError] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleSendOtp = async () => {
    try {
      setOtp('')
      const sendEmail = { email_id: otpData.email }
      const res = await getEmailOtp(sendEmail, setError)
      console.log('res in send ', res)
      if (res !== 204 && res !== 406) {
        setCountdown(120)
        setShowotp(true)
        setDisabled(true)
        setSeverity('success')
        setMes('OTP Sent succesfully')
        setOn(true)
      }
    } catch (err) {
      const c = err.message
      setSeverity('error')
      setMes(c)
      setOn(true)
      console.log('send otp error ', err.message)
    }
  }

  function validateOtp() {
    let valid = true
    if (otp.length !== 6) {
      setOtpError(true)
      valid = false
    }
    return valid
  }

  const removeError = (v1) => {
    if (error && error[v1]) {
      setError((prevError) => ({
        ...prevError,
        [v1]: null,
      }))
    }
  }

  const handleVerifyOtp = async () => {
    try {
      if (true) {
        const sendOtp = { email_id: otpData.email, otp }
        const res = await forgetPassOtpValidate(sendOtp, setError)
        if (res !== 406 && res !== 204) {
          handleOpen()
        }
      }
    } catch (err) {
      const e = err.message
      setSeverity('error')
      setMes(e)
      setOn(true)
    }
  }

  const handleUpdatePass = async () => {
    if (password !== confirmPass) {
      setSeverity('warning')
      setMes('Password did not match !')
      setOn(true)
    } else if (password === confirmPass) {
      const sendPass = {
        email_id: otpData.email,
        otp,
        password,
      }
      try {
        const res = await passwordUpdate(sendPass)
        if (res !== 406 && res !== 204) {
          setSeverity('success')
          setMes('Password update succesfully !')
          setOn(true)
          navigate('/', { replace: true })
        }
      } catch (err) {
        const e = err.message
        setSeverity('error')
        setMes(e)
        setOn(true)
      }
    }
  }

  useEffect(() => {
    if (otp.length === 6) {
      setOtpError(false)
    } else {
      setOtpError(true)
    }
  }, [otp])

  useEffect(() => {
    if (disabled) {
      const timer = setInterval(() => {
        if (countdown === 0) {
          setDisabled(false)
        } else {
          setCountdown(countdown - 1)
        }
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [disabled, countdown])

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return pas ? (
    <>
      {isSmallScreen ? (
        <div className='form-container'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',

              textAlign: 'center',
            }}
          >
            <img
              src={'../images/zealazerbaijanlogo10.png'}
              alt='logo'
              height={70}
            />
            <div>{/* Your card content here */}</div>
          </div>
          <Typography
            className='header'
            sx={{ fontWeight: 'bold', textAlign: 'center', padding: '5px' }}
          >
            Welcome to Zeal Azerbaijan Airlines
          </Typography>
          <div className='textfield'>
            <Typography
              variant='h5'
              sx={{ fontWeight: 'bold', marginBottom: '5px' }}
            >
              Forgot password ?
            </Typography>
            <p style={{ color: 'red', fontSize: '13px', fontWeight: '420' }}>
              {message}
            </p>

            <Grid container sapcing={2}>
              <Grid xs={12}>
                <TextField
                  className='form_style'
                  size='medium'
                  label='Enter Email'
                  sx={{ marginTop: '5%', width: '80%', marginLeft: '38px' }}
                  name='email'
                  value={otpData.email}
                  onChange={(e) =>
                    handleChangeGlobal(
                      e,
                      setOtpData,
                      setError,
                      error,
                      'email_id',
                      'email'
                    )
                  }
                  disabled={disabled}
                  error={(error && error['email_id']) || error['email']}
                />
                <FormHelperText style={{ color: 'red', marginLeft: '26px' }}>
                  {(error && error['email']) || error['email_id']}
                </FormHelperText>
              </Grid>
              <Grid xs={12}>
                <Button
                  className='loginbutton'
                  variant='contained'
                  size='small'
                  sx={{
                    marginBottom: '2%',
                    fontWeight: 'bold',

                    marginTop: '14px',
                    marginLeft: '38px',
                    width: '80%',
                  }}
                  onClick={handleSendOtp}
                  disabled={disabled}
                >
                  {disabled
                    ? `Resent OTP in ${Math.floor(countdown / 60)
                        .toString()
                        .padStart(2, '0')} : ${(countdown % 60)
                        .toString()
                        .padStart(2, 0)}`
                    : 'Send OTP'}
                </Button>
              </Grid>
            </Grid>
            <div className='links'>
              <Button
                size='small'
                variant='text'
                sx={{
                  color: 'black',
                  textDecoration: 'none',
                  fontSize: '11px',
                  fontWeight: 'bold',
                  float: 'left',
                }}
                onClick={() => setPas(!pas)}
              >
                Login with password
              </Button>
            </div>

            {showotp && (
              <>
                <Typography
                  variant='h7'
                  sx={{
                    fontWeight: '500',
                    marginX: '37px',
                    fontSize: '14px',
                    marginTop: '10px',
                  }}
                >
                  Enter OTP *
                  <MuiOtpInput
                    length={6}
                    gap={1}
                    TextFieldsProps={{ size: 'small' }}
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      marginTop: '10px',
                    }}
                    value={otp}
                    onChange={(e) => {
                      setOtp(e)
                      removeError('otp')
                    }}
                  />
                  <helperText style={{ color: 'red' }}>
                    {error && error.otp && 'Please enter valid OTP'}
                  </helperText>
                </Typography>

                <div>
                  <Button
                    className='loginbutton'
                    variant='contained'
                    size='small'
                    onClick={handleVerifyOtp}
                    sx={{
                      marginTop: '18px',
                      fontWeight: 'bold',
                      color: 'black',
                      fontSize: '12px',
                      marginBottom: '20px',
                      width: '280px',
                    }}
                  >
                    VERIFY{' '}
                  </Button>

                  <Modal
                    open={open}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'
                  >
                    <Card sx={style}>
                      <Cancel
                        onClick={handleClose}
                        sx={{
                          position: 'absolute',
                          top: '2px',
                          right: '2px',
                          cursor: 'pointer',
                        }}
                      />
                      <Typography
                        id='modal-modal-title'
                        variant='h6'
                        component='h2'
                      >
                        Create New Password
                      </Typography>
                      <div className='textfield'>
                        <TextField
                          size='small'
                          label='New Password'
                          sx={{ width: '290px' }}
                          type={showPassword ? 'text' : 'password'}
                          name='password'
                          value={password}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='toggle password visibility'
                                  onClick={handleClickShowPassword}
                                  edge='end'
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <TextField
                          size='small'
                          label='Confirm Password'
                          sx={{ width: '290px', marginY: '20px' }}
                          type='text'
                          name='confirmPass'
                          value={confirmPass}
                          onChange={(e) => setConfirmPass(e.target.value)}
                        />
                        <Button
                          className='loginbutton'
                          sx={{
                            fontWeight: 'bold',
                            color: 'black',
                            fontSize: '12px',
                            alignItems: 'center',
                            marginBottom: '20px',
                          }}
                          variant='contained'
                          size='small'
                          onClick={handleUpdatePass}
                        >
                          Submit
                        </Button>
                      </div>
                    </Card>
                  </Modal>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <Card className='formcard' elevation={6} sx={{ borderRadius: 4 }}>
          <CardContent>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',

                textAlign: 'center',
              }}
            >
              <img
                src={'../images/zealazerbaijanlogo10.png'}
                alt='logo'
                height={70}
              />
              <div>{/* Your card content here */}</div>
            </div>
            <Typography className='header' sx={{ fontWeight: '600' }}>
              Welcome to Zeal Azerbaijan Airlines
            </Typography>
            <div className='textfield'>
              <Typography
                variant='h5'
                sx={{ fontWeight: 'bold', marginBottom: '5px' }}
              >
                Forgot password ?
              </Typography>
              <p style={{ color: 'red', fontSize: '13px', fontWeight: '420' }}>
                {message}
              </p>

              <Grid container sapcing={2}>
                <Grid xs={12}>
                  <TextField
                    className='form_style'
                    size='small'
                    label='Enter Email'
                    sx={{ width: '85%', marginTop: '5%', marginLeft: '24px' }}
                    name='email'
                    value={otpData.email}
                    onChange={(e) =>
                      handleChangeGlobal(
                        e,
                        setOtpData,
                        setError,
                        error,
                        'email_id',
                        'email'
                      )
                    }
                    disabled={disabled}
                    error={(error && error['email_id']) || error['email']}
                  />
                  <FormHelperText style={{ color: 'red', marginLeft: '26px' }}>
                    {(error && error['email']) || error['email_id']}
                  </FormHelperText>
                </Grid>
                <Grid xs={12}>
                  <Button
                    className='loginbutton'
                    variant='contained'
                    color='authBtn'
                    size='small'
                    sx={{
                      marginBottom: '2%',
                      fontWeight: 'bold',
                      color: 'black',
                      marginLeft: '24px',
                      marginTop: '14px',
                    }}
                    onClick={handleSendOtp}
                    disabled={disabled}
                  >
                    {disabled
                      ? `Resent OTP in ${Math.floor(countdown / 60)
                          .toString()
                          .padStart(2, '0')} : ${(countdown % 60)
                          .toString()
                          .padStart(2, 0)}`
                      : 'Send OTP'}
                  </Button>
                </Grid>
              </Grid>
              <div className='links'>
                <Button
                  size='small'
                  variant='text'
                  sx={{
                    textDecoration: 'none',
                    fontSize: '11px',
                    fontWeight: 'bold',
                    float: 'left',
                  }}
                  onClick={() => setPas(!pas)}
                >
                  Login with password
                </Button>
              </div>

              {showotp && (
                <>
                  <Typography
                    variant='h7'
                    sx={{
                      fontWeight: '500',
                      marginX: '25px',
                      fontSize: '14px',
                      marginTop: '10px',
                    }}
                  >
                    Enter OTP *
                    <MuiOtpInput
                      length={6}
                      gap={1}
                      TextFieldsProps={{ size: 'small' }}
                      sx={{
                        marginBottom: '1rem',
                        width: '100%',
                        marginTop: '10px',
                      }}
                      value={otp}
                      onChange={(e) => {
                        setOtp(e)
                        removeError('otp')
                      }}
                    />
                    <helperText style={{ color: 'red' }}>
                      {error && error.otp && 'Please enter valid OTP'}
                    </helperText>
                  </Typography>

                  <div>
                    <Button
                      className='loginbutton'
                      variant='contained'
                      size='small'
                      onClick={handleVerifyOtp}
                      sx={{
                        marginTop: '18px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '12px',
                        marginBottom: '20px',
                        width: '280px',
                      }}
                    >
                      VERIFY{' '}
                    </Button>

                    <Modal
                      open={open}
                      aria-labelledby='modal-modal-title'
                      aria-describedby='modal-modal-description'
                    >
                      <Card sx={style}>
                        <Cancel
                          onClick={handleClose}
                          sx={{
                            position: 'absolute',
                            top: '8px',
                            right: '8px',
                            cursor: 'pointer',
                          }}
                        />
                        <Typography
                          id='modal-modal-title'
                          variant='h6'
                          component='h2'
                        >
                          Create New Password
                        </Typography>
                        <div className='textfield'>
                          <TextField
                            size='small'
                            label='New Password'
                            sx={{ width: '290px' }}
                            type={showPassword ? 'text' : 'password'}
                            name='password'
                            value={password}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={handleClickShowPassword}
                                    edge='end'
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <TextField
                            size='small'
                            label='Confirm Password'
                            sx={{ width: '290px', marginY: '20px' }}
                            type='text'
                            name='confirmPass'
                            value={confirmPass}
                            onChange={(e) => setConfirmPass(e.target.value)}
                          />
                          <Button
                            className='loginbutton'
                            sx={{
                              fontWeight: 'bold',
                              color: 'black',
                              fontSize: '12px',
                              alignItems: 'center',
                              marginBottom: '20px',
                            }}
                            variant='contained'
                            size='small'
                            onClick={handleUpdatePass}
                          >
                            Submit
                          </Button>
                        </div>
                      </Card>
                    </Modal>
                  </div>
                </>
              )}
            </div>
          </CardContent>
        </Card>
      )}
    </>
  ) : (
    <Loginform />
  )
}

export default Forgotpassword
