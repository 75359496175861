import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBackward,
  faBars,
  faBan,
  faBold,
  faCheck,
  faCheckSquare,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faCloudUploadAlt,
  faCircle,
  faCode,
  faCog,
  faCompressAlt,
  faDollarSign,
  faDownload,
  faExclamationCircle,
  faExclamationTriangle,
  faExpandAlt,
  faEye,
  faEyeSlash,
  faFilter,
  faFlag,
  faFont,
  faForward,
  faImage,
  faIndent,
  faItalic,
  faLink,
  faListOl,
  faListUl,
  faLock,
  faLongArrowAltRight,
  faMapMarkerAlt,
  faOutdent,
  faPaintBrush,
  faParagraph,
  faPencilAlt,
  faPrint,
  faQuestion,
  faQuoteRight,
  faSearch,
  faSearchPlus,
  faSearchMinus,
  faSortUp,
  faSortDown,
  faSquare,
  faStepBackward,
  faStepForward,
  faStrikethrough,
  faSyncAlt,
  faTable,
  faTint,
  faTrashAlt,
  faUnderline,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";

function iconLibrary() {
  library.add(
    faAlignCenter,
    faAlignJustify,
    faAlignLeft,
    faAlignRight,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faBackward,
    faBars,
    faBan,
    faBold,
    faCheck,
    faCheckSquare,
    faChevronDown,
    faChevronRight,
    faChevronLeft,
    faChevronUp,
    faCloudUploadAlt,
    faCircle,
    faCode,
    faCog,
    faCompressAlt,
    faDollarSign,
    faDownload,
    faExclamationCircle,
    faExclamationTriangle,
    faExpandAlt,
    faEye,
    faEyeSlash,
    faFilter,
    faFlag,
    faFont,
    faForward,
    faImage,
    faIndent,
    faItalic,
    faLink,
    faListOl,
    faListUl,
    faLock,
    faLongArrowAltRight,
    faMapMarkerAlt,
    faOutdent,
    faPaintBrush,
    faParagraph,
    faPencilAlt,
    faPrint,
    faQuestion,
    faQuoteRight,
    faSearch,
    faSearchPlus,
    faSearchMinus,
    faSortUp,
    faSortDown,
    faSquare,
    faStepBackward,
    faStepForward,
    faStrikethrough,
    faSyncAlt,
    faTable,
    faTint,
    faTrashAlt,
    faUnderline,
    faUser  
  )
}

export default iconLibrary;
